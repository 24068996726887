import { styled } from "styled-components";
import { Button } from "@wayflyer/flyui";

import type { TaskStatus } from "./task-indicator";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
    flex-direction: column;
    align-items: start;
  }
`;
export const TextContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing("3")};
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing("3")};
  }
`;

interface IconBorderProps {
  status: TaskStatus;
}

export const IconBorder = styled.span<IconBorderProps>`
  height: ${({ theme }) => theme.spacing("10")};
  width: ${({ theme }) => theme.spacing("10")};
  margin-top: ${({ theme }) => theme.spacing("1")};
  background-color: ${({ theme, status }) =>
    status === "pending" ? theme.palette.utility.neutral.weak : theme.palette.utility.success.weak};
  border: 0px solid transparent;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  svg {
    @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
      width: 44px;
    }
    color: ${({ theme, status }) => status === "pending" && theme.palette.icon.default};
  }
`;

export const ContentAside = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
    align-items: flex-start;
    width: 100%;
  }
`;

export const ButtonAside = styled(ContentAside)`
  @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
    justify-content: right;
  }
`;

export const DescriptionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing("1")};
  @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
    margin-top: 0;
  }
`;

export const TaskCardSection = styled.div`
  padding: ${({ theme }) => theme.spacing("6")};
  @media (max-width: ${({ theme }) => theme.breakpoint("lg")}) {
    padding: ${({ theme }) => theme.spacing("5")};
  }
`;

export const CTAButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
    max-width: calc(100% - 52px);
  }
`;
