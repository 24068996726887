import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import type { AppConfig } from "../../types";

export function usePostHogIdentify(appConfig: AppConfig) {
  const posthog = usePostHog();
  useEffect(() => {
    if (posthog && appConfig.analyticsAttributes.userId) {
      posthog.register_once({ event_source: "app" });
      const distinctId = appConfig.userEmail;
      posthog.identify(distinctId, {
        email: appConfig.userEmail,
        name: appConfig.userName,
        customer_id: appConfig.customerId,
        customer_name: appConfig.customerName,
        customer_domain: appConfig.customerDomain,
        company_public_id: appConfig.analyticsAttributes.companyPublicId ?? undefined,
        country_of_incorporation: appConfig.countryOfIncorporation ?? undefined,
        is_staff: appConfig.analyticsAttributes.isStaff ?? false,
        is_test: appConfig.analyticsAttributes.isTest ?? false,
        hubspot_contact_id: appConfig.analyticsAttributes.hubspotContactId ?? undefined,
      });
      posthog.alias(appConfig.analyticsAttributes.userId.toString(), distinctId);
      if (appConfig.analyticsAttributes.companyPublicId) {
        posthog.group("company", appConfig.analyticsAttributes.companyPublicId, {
          name: appConfig.customerName,
          company_name: appConfig.customerName,
          company_domain: appConfig.customerDomain,
          company_public_id: appConfig.analyticsAttributes.companyPublicId,
          country_of_incorporation: appConfig.countryOfIncorporation,
          industry: appConfig.industry ?? undefined,
        });
      }
    }
  }, [appConfig, posthog]);
}
