import { styled } from "styled-components";

/**
 * TaskList component
 *
 * TODO: replace this with Stack
 */

export const TaskList = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing("6")};
  & > div {
    &:not(:first-child) {
      margin-top: ${({ theme }) => theme.spacing("7")};
    }
  }
`;
