import { Link, Flex, Logo, FlyErrorComponent, type ErrorComponentProps } from "@wayflyer/flyui";

export const ErrorComponent = (props: ErrorComponentProps) => {
  return (
    <Flex direction="column" padding={["4"]} minHeight="100vh" minWidth="100%" gap="16">
      <Link url="/">
        <Flex maxWidth="200px">
          <Logo type="auth" />
        </Flex>
      </Link>
      <FlyErrorComponent {...props} />
    </Flex>
  );
};
