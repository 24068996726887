export const getBundleVersion = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore ts(2339)
  const version = __APP_VERSION__;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore ts(2339)
  window.__APP_VERSION__ = version;

  if (!version) {
    // eslint-disable-next-line no-console
    import.meta.env.PROD && console.info("No app version found!");
    return;
  }

  return version;
};

export const getAppName = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore ts(2339)
  const appName: string = __APP_NAME__;

  if (!appName) {
    throw new Error("No app name found!");
  }

  return appName;
};
