import Cookies from "js-cookie";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { AuthnMfaMethod, getWfAuthnClient, getWfAuthnMfaClient, WfAccessToken, type AuthnEnrolledMfa } from "./client";

type WfAuthnValue = {
  isFetching: boolean;

  currentMfaApiToken: WfAccessToken | undefined;
  currentStepUpToken: WfAccessToken | undefined;

  enrolledMfaMethods: AuthnEnrolledMfa[];
  methodToRemove: AuthnEnrolledMfa | null;

  enroll: (args: { mfaMethod: AuthnMfaMethod; next?: string }) => Promise<void>;
  stepUpMfaApi: (args?: { next?: string }) => Promise<void>;
  hasMfaEnrolled: () => Promise<boolean | undefined>;
  removeMfaMethod: (id: string) => Promise<void>;
  setMethodToRemove: (method: AuthnEnrolledMfa | null) => void;

  stepUp: (args?: { next?: string }) => Promise<void>;
  getStepUpToken: () => Promise<string | undefined>;
  hasStepUpToken: () => Promise<boolean>;
};

const useAuth0WfAuthn = (): WfAuthnValue => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [currentMfaApiToken, setCurrentMfaApiToken] = useState<WfAccessToken | undefined>(undefined);
  const [currentStepUpToken, setCurrentStepUpToken] = useState<WfAccessToken | undefined>(undefined);
  const [enrolledMfaMethods, setEnrolledMfaMethods] = useState<AuthnEnrolledMfa[]>([]);
  const [methodToRemove, setMethodToRemove] = useState<AuthnEnrolledMfa | null>(null);

  const location = useLocation();
  const wfAuthn = getWfAuthnClient();
  const wfAuthnMfa = getWfAuthnMfaClient();

  const getCurrentTokens = useCallback(async () => {
    setIsFetching(true);
    setCurrentMfaApiToken(await wfAuthn.getMfaApiToken());
    setCurrentStepUpToken(await wfAuthn.getStepUpToken());
    setIsFetching(false);
  }, [wfAuthn]);

  useEffect(() => {
    if (location.pathname === "/authn-wf/callback") {
      // We need to wait until we're redirected off the callback page to get the new tokens.
      return;
    }

    getCurrentTokens();
  }, [location.pathname, getCurrentTokens]);

  const verifyMfaApiTokenIsValid = useCallback(async () => {
    if (!(await wfAuthn.hasMfaApiToken())) {
      // clear objects related to a valid MFA API token
      setCurrentMfaApiToken(undefined);
      setEnrolledMfaMethods([]);
      setMethodToRemove(null);
      return false;
    } else {
      return true;
    }
  }, [wfAuthn]);

  const stepUpMfaApi = useCallback(
    async (args?: { next?: string }) => {
      // To list and remove existing MFA methods, the user must have an MFA API token. This is a separate token from our
      // app tokens. This call will navigate the user to Auth0 and back again to get an MFA API token to the given `next`
      // path.
      //
      // This token lasts 10 minutes as a hard requirement from Auth0.
      //
      // Additionally:
      //  - If the user has no enrolled MFA methods, they will return immediately with a valid token.
      //  - If the user has only enrolled WebAuthn, they will be prompted to enroll another method first.
      //  - Otherwise, they will be prompted to complete an MFA challenge.
      setIsFetching(true);
      await wfAuthn.stepUpMfaApi(args?.next);
    },
    [wfAuthn]
  );

  const enroll = useCallback(
    // This will navigate the user away from this page and to Auth0 to enroll in the given MFA method.
    //
    // Once the user completes the Auth0-side, they get redirected back to an Authn-owned callback route
    // (/authn-wf/callback). The callback route then redirects the user to the given `next`.
    //
    // Additionally:
    //  - Currently, if the user is already enrolled in the given MFA method, they will immediately be redirected
    //    back to the given `next` path.
    //  - Prior to enrolling the new method, they will be prompted for any existing MFA method if they have one. This
    //    is a hard requirement of the Auth0 API.
    //  - An MFA API token is not required for this operation.
    async (args: { mfaMethod: AuthnMfaMethod; next?: string }) => {
      if (!(await verifyMfaApiTokenIsValid())) {
        return;
      }

      await wfAuthn.enroll(args.mfaMethod, args.next);
    },
    [verifyMfaApiTokenIsValid, wfAuthn]
  );

  const fetchMfaMethods = useCallback(async () => {
    if (!(await verifyMfaApiTokenIsValid())) {
      return;
    }
    // Get the set of currently enrolled MFA methods
    if (currentMfaApiToken) {
      const methods = await wfAuthnMfa.get();
      setEnrolledMfaMethods(methods);
      setIsFetching(false);
    }
  }, [verifyMfaApiTokenIsValid, currentMfaApiToken, wfAuthnMfa]);

  const removeMfaMethod = useCallback(
    async (id: string) => {
      if (!(await verifyMfaApiTokenIsValid())) {
        return;
      }
      await wfAuthnMfa.remove(id);
      setMethodToRemove(null);
      fetchMfaMethods();
    },
    [verifyMfaApiTokenIsValid, wfAuthnMfa, fetchMfaMethods]
  );

  const hasMfaEnrolled = useCallback(async () => {
    return await wfAuthn.hasMfaEnrolled();
  }, [wfAuthn]);

  const stepUp = useCallback(
    async (args?: { next?: string }) => {
      // This will navigate the user to Auth0 to perform step-up and back again. Any MFA method is acceptable for
      // step-up.
      //
      // This will show the user an error page if they are not enrolled in any MFA method.
      setIsFetching(true);
      await wfAuthn.stepUp(args?.next);
    },
    [wfAuthn]
  );

  const getStepUpToken = useCallback(
    async (args?: { refresh?: boolean }) => {
      setIsFetching(true);
      const token = await wfAuthn.getStepUpToken({
        refresh: args?.refresh,
      });
      setCurrentStepUpToken(token);
      setIsFetching(false);
      return token?.jwt;
    },
    [wfAuthn]
  );

  const hasStepUpToken = useCallback(async () => {
    return await wfAuthn.hasStepUpToken();
  }, [wfAuthn]);

  useEffect(() => {
    fetchMfaMethods();
  }, [fetchMfaMethods]);

  return {
    enrolledMfaMethods,
    isFetching,
    currentMfaApiToken,
    currentStepUpToken,
    methodToRemove,

    enroll,
    stepUpMfaApi,
    hasMfaEnrolled,
    removeMfaMethod,
    setMethodToRemove,

    stepUp,
    getStepUpToken,
    hasStepUpToken,
  };
};

export const useStubWfAuthn = (): WfAuthnValue => {
  const mfa: WfAuthnValue = useMemo(
    () => ({
      currentMfaApiToken: undefined,
      currentStepUpToken: undefined,
      enrolledMfaMethods: [],
      isFetching: false,
      methodToRemove: null,

      enroll: () => Promise.resolve(),
      stepUpMfaApi: () => Promise.resolve(),
      hasMfaEnrolled: () => Promise.resolve(false),
      removeMfaMethod: () => Promise.resolve(),
      setMethodToRemove: () => {},

      stepUp: () => Promise.resolve(),
      getStepUpToken: () => Promise.resolve(undefined),
      hasStepUpToken: () => Promise.resolve(false),
    }),
    []
  );

  return mfa;
};

export const useMockWfAuthn = (): WfAuthnValue => {
  const navigate = useNavigate();

  const mfa: WfAuthnValue = useMemo(
    () => ({
      // TODO: Currently all static.
      currentMfaApiToken: { jwt: "blash" } as WfAccessToken,
      currentStepUpToken: { jwt: "blash" } as WfAccessToken,
      enrolledMfaMethods: [
        {
          id: "blas",
          active: true,
          name: "steve",
          type: AuthnMfaMethod.OTP,
        },
      ],
      isFetching: false,
      methodToRemove: null,

      // TODO: All of these are no-op.
      enroll: async () => {},
      stepUpMfaApi: async () => {},
      hasMfaEnrolled: () => Promise.resolve(false),
      removeMfaMethod: async () => {},
      setMethodToRemove: () => {},

      /**
       * Instantly redirects to the next path.
       */
      stepUp: (args?: { next?: string }) => {
        navigate(args?.next ?? window.location.pathname);
        return Promise.resolve();
      },

      /**
       * Assumes the current access token has been injected with `{"scope": "sudo"}` in Authn devmode.
       */
      getStepUpToken: () => {
        return Promise.resolve(Cookies.get("wayflyer-accessToken-user") ?? undefined);
      },

      /**
       * Always returns true.
       */
      hasStepUpToken: () => {
        return Promise.resolve(true);
      },
    }),
    [navigate]
  );

  return mfa;
};

export const useWfAuthnInstance = (): WfAuthnValue => {
  /* eslint-disable react-hooks/rules-of-hooks */
  // Rule of hooks doesn't apply here because these never change.
  if (import.meta.env.VITE_WF_AUTHN_USE_MOCK_HOOK === "true") {
    return useMockWfAuthn();
  } else if (process.env.NODE_ENV === "test") {
    return useStubWfAuthn();
  } else {
    return useAuth0WfAuthn();
  }
};

const WfAuthn = createContext<WfAuthnValue | undefined>(undefined);

export const WfAuthnProvider = ({ children, authn }: { children: React.ReactNode; authn?: WfAuthnValue }) => {
  if (!authn) {
    authn = useWfAuthnInstance();
  }

  return <WfAuthn.Provider value={authn}>{children}</WfAuthn.Provider>;
};

export const useWfAuthn = () => {
  const authn = useContext(WfAuthn);
  if (!authn) {
    throw new Error("useWfAuthn must be used within a WfAuthnProvider");
  }

  return authn;
};
