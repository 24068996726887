import * as yup from "yup";

const isEmail = (email: string) => yup.string().email().isValidSync(email);

export const emailMask = (text: string) => {
  const rPunctuationMark = /[.,!?:;]/g;
  return text
    .split(" ")
    .map((word) => {
      let punctuationMark;
      if (!!word && word[word.length - 1].match(rPunctuationMark)) {
        punctuationMark = word[word.length - 1];
        word = word.slice(0, -1);
      }
      if (!!word && isEmail(word)) {
        const charArray = word.split("@");
        word =
          word[0] +
          "*".repeat(charArray[0].length - 1) +
          "@" +
          "*".repeat(charArray[1].length - 1) +
          word[word.length - 1];
      }
      return punctuationMark ? word + punctuationMark : word;
    })
    .join(" ");
};
