import { styled, css } from "styled-components";
import { UnstyledButton } from "@wayflyer/flyui";

import type { InteractiveBadgeProps } from "./interactive-badge";

export const StyledBadge = styled(UnstyledButton)<{
  $status: InteractiveBadgeProps["status"];
}>`
  height: 22px;
  ${({ theme }) => theme.text("sm")};
  font-weight: ${({ theme }) => theme.fontWeight("normal")};
  display: inline-flex;
  align-items: center;
  color: ${({ $status, theme }) => ($status ? theme.palette.text[$status] : theme.palette.text.default)};
  gap: ${({ theme }) => theme.spacing("1")};

  &:focus-visible {
    outline: 1px dotted;
  }

  ${({ theme, $status }) => {
    return css`
      border-radius: ${({ theme }) => theme.spacing("2")};
      padding: ${theme.spacing(["1", "3"])};
      background: ${$status ? String(theme.palette.status[$status]) : theme.palette.utility.neutral.weak};
    `;
  }}
`;
