export const getNumDaysSinceDate = (dateStr: string): number => {
  const today = new Date();
  const date = new Date(dateStr);
  const oneDayMs = 24 * 60 * 60 * 1000; // number of milliseconds in a day
  const diffMs = today.getTime() - date.getTime();
  const diffDays = Math.round(diffMs / oneDayMs);
  return diffDays;
};

export const convertNumDaysToDateRange = (daysPassed: number): string => {
  const oneWeekDays = 7;
  const oneMonthDays = 30;
  const threeMonthsDays = 3 * oneMonthDays;
  const sixMonthsDays = 6 * oneMonthDays;
  const oneYearDays = 12 * oneMonthDays;

  if (daysPassed <= oneWeekDays) {
    return "within a week";
  } else if (daysPassed <= oneMonthDays) {
    return "within a month";
  } else if (daysPassed <= threeMonthsDays) {
    return "within 3 months";
  } else if (daysPassed <= sixMonthsDays) {
    return "within 6 months";
  } else if (daysPassed <= oneYearDays) {
    return "within a year";
  } else {
    return "longer than a year";
  }
};
