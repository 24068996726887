import { useSuspenseQuery, type UseSuspenseQueryResult, type UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useAxios } from "@wayflyer/auth-sdk";
import { BaseSchemas } from "api-types";
import type { AxiosError, AxiosResponse } from "axios";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { StaffAppConfigSchemaOut } from "staff-v2-types";

export const STAFF_APP_CONFIG_QUERY_KEY = "staff-app-config";
export const CUSTOMER_APP_CONFIG_QUERY_KEY = "customer-app-config";

export const useStaffAppConfig = (): UseSuspenseQueryResult<StaffAppConfigSchemaOut> => {
  const headers = { "x-current-location": window.location.pathname };
  const axiosClient = useAxios();
  return useSuspenseQuery<AxiosResponse<StaffAppConfigSchemaOut>, AxiosError, StaffAppConfigSchemaOut>({
    queryKey: [STAFF_APP_CONFIG_QUERY_KEY],
    queryFn: () => axiosClient.get<StaffAppConfigSchemaOut>("/staff/v2/api/app-config", { headers }),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: Infinity,
    select: ({ data }) => data,
  });
};

export const useCustomerAppConfig = (
  options: Omit<
    UseSuspenseQueryOptions<AxiosResponse<BaseSchemas.CustomerAppConfig>, AxiosError, BaseSchemas.CustomerAppConfig>,
    "queryKey" | "queryFn" | "select"
  > = {}
): UseSuspenseQueryResult<BaseSchemas.CustomerAppConfig> => {
  const headers = { "x-current-location": location.pathname };
  const axiosClient = useAxios();
  return useSuspenseQuery<AxiosResponse<BaseSchemas.CustomerAppConfig>, AxiosError, BaseSchemas.CustomerAppConfig>({
    queryKey: [CUSTOMER_APP_CONFIG_QUERY_KEY],
    queryFn: () => axiosClient.get<BaseSchemas.CustomerAppConfig>("/app-config", { headers }),
    select: ({ data }) => data,
    refetchOnMount: "always",
    ...options,
  });
};
