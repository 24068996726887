import { Link, Modal, Text } from "@wayflyer/flyui";
import { FormattedMessage, useIntl } from "react-intl";
import { handleContactTeam } from "shared-utils";

export const CompanyInactiveModal = ({ isOpen }: { isOpen: boolean }) => {
  const intl = useIntl();
  return (
    <Modal
      analyticsAttrs={{ type: "inactive" }}
      title={intl.formatMessage({
        id: "shared-ui: customer inactive title",
        defaultMessage: "Please Contact Support",
      })}
      isOpen={isOpen}
      closeOnClickElseWhere={false}
    >
      <FormattedMessage
        id="shared-ui: customer inactive error message"
        defaultMessage="<p>Your Wayflyer account is closed. To reactivate your account please use our <a1>online chat</a1> or contact support by emailing <a2>support@wayflyer.com</a2></p>"
        values={{
          p: (chunks) => <Text>{chunks}</Text>,
          a1: (chunks) => <Link onClick={handleContactTeam}>{chunks}</Link>,
          a2: (chunks) => <Link url="mailto:support@wayflyer.com">{chunks}</Link>,
        }}
      />
    </Modal>
  );
};
