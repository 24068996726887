import type { FundingApplicationSchemas } from "api-types";
import { type IntlShape } from "react-intl";

export const getPlatformStringFromConnectionCategory = (
  intl: IntlShape,
  airReconnectionType: string | undefined | null,
  connectionName: string
) => {
  switch (airReconnectionType) {
    case "SHOPPING":
      if (connectionName === "Stripe") {
        return intl.formatMessage({
          id: "shared-ui: air connected platform payment",
          defaultMessage: "payment platform",
        });
      }
      return intl.formatMessage({
        id: "shared-ui: air connected platform shopping",
        defaultMessage: "eCommerce platform",
      });
    case "ANALYTICS":
      return intl.formatMessage({
        id: "shared-ui: air connected platform analytics",
        defaultMessage: "analytics platform",
      });
    case "MARKETING":
      return intl.formatMessage({
        id: "shared-ui: air connected platform marketing",
        defaultMessage: "marketing platform",
      });
    case "BANKING":
      return intl.formatMessage({
        id: "shared-ui: air connected platform banking",
        defaultMessage: "bank accounts",
      });
  }
  return intl.formatMessage({ id: "shared-ui: air connected platform default", defaultMessage: "platform" });
};

// Ideally, move this logic to AIR details endpoint in the future
// Rename connectors for FE where required
// e.g. Facebook Ads -> Meta Ads
export const getConnectorNameForFE = (connectorName: string) => {
  if (connectorName === "Facebook Ads") {
    return "Meta Ads";
  }
  return connectorName;
};

export const formatRevenueAmount = (
  revenueAmount: FundingApplicationSchemas.RevenueSourceAirAmount | null | undefined,
  intl: IntlShape
): string => {
  if (revenueAmount === undefined || revenueAmount === null) {
    return intl.formatNumber(0, {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      maximumFractionDigits: 0,
    });
  }

  const { amount_in_lowest_denomination, currency } = revenueAmount;
  const amount = amount_in_lowest_denomination / 100;

  return intl.formatNumber(amount, {
    style: "currency",
    currency,
    currencyDisplay: currency === "USD" ? "narrowSymbol" : "symbol",
    maximumFractionDigits: 0,
  });
};
