import type { NavigationProps, NavItemButton, NavItemLink, NavSection } from "@wayflyer/flyui";
import { AppShell } from "@wayflyer/flyui";
import {
  IconArrowsRightLeft24Line,
  IconArrowTrendingUp24Line,
  IconBanknotes24Line,
  IconBenchmarks24Line,
  IconBookOpen24Line,
  IconBuildingOffice24Line,
  IconChartBars24Line,
  IconCheckSquares24Line,
  IconCoinDollar24Line,
  IconDocument24Line,
  IconGift24Line,
  IconPeople224Line,
  IconPersonCircle24Line,
  IconPersonPlus24Line,
  IconQuestionMarkCircle24Line,
  IconWrench24Line,
} from "@wayflyer/flyui-icons/24/line";
import { BaseSchemas } from "api-types";
import { upperFirst } from "lodash";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";

import { getNavItemNameFromId } from "./nav-item-strings";
import { useNavTaskNotificationCount } from "./nav-task-notification-count";

declare global {
  interface Window {
    Optanon: {
      ToggleInfoDisplay: () => void;
    };
  }
}

function getIconComponent(icon: string): ReactNode | undefined {
  if (typeof icon === "string") {
    switch (icon) {
      case "bank":
        return <IconBanknotes24Line />;
      case "line-chart":
        return <IconChartBars24Line />;
      case "team":
        return <IconPersonPlus24Line />;
      case "help":
        return <IconQuestionMarkCircle24Line />;
      case "connections":
        return <IconArrowsRightLeft24Line />;
      case "presentation-chart-line":
        return <IconChartBars24Line />;
      case "benchmarks":
        return <IconBenchmarks24Line />;
      case "banking":
        return <IconBuildingOffice24Line />;
      case "financial-os":
        return <IconBenchmarks24Line />; // TODO: update
      case "internal":
        return <IconWrench24Line />;
      case "dollar":
        return <IconCoinDollar24Line />;
      case "share":
        return <IconGift24Line />;
      case "account":
        return <IconPersonCircle24Line />;
      case "payments":
        return <IconBookOpen24Line />;
      case "recipients":
        return <IconPeople224Line />;
      case "transactions":
        return <IconArrowTrendingUp24Line />;
      case "documents":
        return <IconDocument24Line />;
      case "tasks":
        return <IconCheckSquares24Line />;
      default:
        return;
    }
  }
}

const navItemMatches = (path: string, alternativePaths: string[] = [], pathname: string): boolean => {
  if (path === pathname || alternativePaths.includes(pathname)) {
    return true;
  } else {
    return alternativePaths.some((alternativePath) => pathname.startsWith(alternativePath));
  }
};

export const useNavigationItems = ({
  appConfig,
  setInviteModalOpen,
  clientNavigateFn,
}: {
  appConfig: BaseSchemas.CustomerAppConfig;
  setInviteModalOpen: (isOpen: boolean) => void;
  clientNavigateFn: (to: string) => void;
}): NavigationProps => {
  const intl = useIntl();
  const { navItems, userName, customerDomain } = appConfig;
  const taskNotifications = useNavTaskNotificationCount(customerDomain);

  const alternativePathsMap = {};

  for (const navItem of navItems ?? []) {
    if (navItem.alternativePaths) {
      alternativePathsMap[navItem.path] = navItem.alternativePaths;
    }

    if (navItem.subItems) {
      for (const subItem of navItem.subItems) {
        if (subItem.alternativePaths) {
          alternativePathsMap[subItem.path] = subItem.alternativePaths;
        }
      }
    }
  }

  const getIsRouteSelected = (pathname: string): boolean => {
    return navItemMatches(pathname, alternativePathsMap[pathname], location.pathname);
  };

  const getUserName = (userName: string): string => {
    const regex = /^full_name:([a-zA-Z.]+)(\+.*)?@wayflyer\.com$/;
    const match = userName.match(regex);

    if (match) {
      // Extract the name part from the email
      const namePart = match[1];

      // Replace dots with spaces, split the name into parts, and capitalize each word
      const normalized = namePart
        .split(".")
        .map((str) => upperFirst(str.toLowerCase()))
        .join(" ");

      return normalized;
    }

    return userName;
  };

  function getClientSubNavItems(
    navItems: BaseSchemas.CustomerAppConfig["navItems"]
  ): Array<NavItemButton | NavItemLink> | undefined {
    if (!navItems || navItems.length === 0) {
      return;
    }

    return navItems.map(({ id, icon, path }) => {
      return {
        id,
        href: path,
        icon: getIconComponent(icon),
        label: getNavItemNameFromId(intl, id),
        ...(path.includes("banking") && { navigateFn: clientNavigateFn }),
      };
    });
  }

  const profileSection: NavigationProps["profileSection"] = {
    label: <AppShell.ProfileMenuItem userName={getUserName(userName)} companyName={appConfig.customerName} />,
    id: "profile-section",
    icon: null,
    items: [
      {
        id: "settings",
        label: intl.formatMessage({ id: "Menu: Settings", defaultMessage: "Settings" }),
        href: `/${appConfig?.customerDomain}/settings`,
      },
      {
        id: "invite",
        label: intl.formatMessage({ id: "Invite someone", defaultMessage: "Invite a team member" }),
        onClick: () => setInviteModalOpen(true),
      },
      ...(!navItems || navItems.length === 0
        ? [
            {
              id: "help",
              label: intl.formatMessage({ id: "help centre title", defaultMessage: "Help center" }),
              href: "https://www.wayflyer.com/help-center",
              external: true,
            },
          ]
        : []),
      ...(window?.Optanon?.ToggleInfoDisplay
        ? [
            {
              id: "privacy-settings",
              label: intl.formatMessage({
                id: "Privacy settings",
                defaultMessage: "Privacy settings",
              }),
              onClick: () => window.Optanon.ToggleInfoDisplay(),
            },
          ]
        : []),
      {
        id: "sign-out",
        label: intl.formatMessage({ id: "Sign out", defaultMessage: "Sign out" }),
        error: true,
        href: "/authn/logout",
      },
    ],
  };

  const NAVIGABLE_SECTIONS = ["banking/payments", "banking/accounts"];

  const toClientNavItem = (navItem: BaseSchemas.NavItem): NavItemLink | NavSection => {
    const { id } = navItem;
    const items = getClientSubNavItems(navItem.subItems);
    const icon = getIconComponent(navItem.icon);
    const label = getNavItemNameFromId(intl, navItem.id);
    const navigableSection = navItem.path && NAVIGABLE_SECTIONS.some((section) => navItem.path.includes(section));

    if (items) {
      return {
        id,
        icon,
        label,
        items,
        ...(navigableSection && { href: navItem.path, navigateFn: clientNavigateFn }), // Temp workaround for banking clickable section - will follow up with platform to agree a better pattern
      };
    } else {
      return {
        id,
        icon,
        label,
        href: navItem.path,
        ...(navItem.path.includes("banking") && { navigateFn: clientNavigateFn }), // Temp workaround to support banking client side routing
      };
    }
  };

  const sections: NavigationProps["sections"] = {
    navItems: (navItems ?? []).map(toClientNavItem).filter((item) => !item.href?.includes("customer-referrals")), //@todo: do this on the server,
  };

  const infoItems = [
    ...(navItems ?? []).map(toClientNavItem).filter((item) => item.href?.includes("customer-referrals")), //@todo: do this on the server,
    ...(navItems && navItems.length !== 0
      ? [
          {
            id: "help",
            label: intl.formatMessage({ id: "help centre title", defaultMessage: "Help center" }),
            href: "https://www.wayflyer.com/help-center",
            icon: <IconQuestionMarkCircle24Line />,
            external: true,
          },
        ]
      : []),
  ] as Array<NavItemLink>;

  const notificationBubble = {
    navItemId: "customer tasks page title",
    count: taskNotifications > 0 ? taskNotifications : undefined,
    title: "customer tasks page title",
  };

  return {
    profileSection,
    sections,
    infoItems,
    getIsRouteSelected,
    theme: "dark",
    notificationBubble,
  };
};
