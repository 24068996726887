import { addError, initialise } from "@wayflyer/blackbox-fe";
import { getBundleVersion } from "shared-utils";
import { viewPathsOwners, displayOwners } from "shared-ui";
import { createBrowserHistory } from "history";
import { matchRoutes } from "react-router-dom";

import * as app from "./app";
import { baseConfig } from "./libs/configs/datadog";
import { ENV_CONFIG } from "./env";
import { router } from "./router";

const env = ENV_CONFIG.PROD && !window.origin.includes("dev") ? "prod" : "local";
const version = getBundleVersion() ?? `customer-web@${ENV_CONFIG.PACKAGE_VERSION}`;

initialise({
  datadog: {
    ...baseConfig[env],
    version,
  },
  viewPathsOwners,
  displayOwners,
});

const browserHistory = createBrowserHistory();

const updateTitle = (location) => {
  try {
    const matches = matchRoutes(router.routes, location);
    const lastMatch = matches?.[matches.length - 1];
    const title = (lastMatch?.route?.handle as { title?: string })?.title;
    document.title = `Wayflyer${env === "prod" ? "" : " (dev)"}${title ? ` - ${title}` : ""}`;
  } catch (error) {
    addError(new Error("Error setting page title", { cause: error }));
  }
};

updateTitle(window.location);

browserHistory.listen(({ location }) => {
  updateTitle(location);
});

app.renderReactPage();
