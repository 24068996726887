import { Flex, Text } from "@wayflyer/flyui";
import { styled } from "styled-components";

export interface TaskGroupProps {
  title?: string;
  children?: React.ReactNode;
}

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing("6")};
  margin-bottom: ${({ theme }) => theme.spacing("2")};
`;

export const TaskGroup = ({ title, children, ...props }: TaskGroupProps) => {
  return (
    <Container {...props}>
      <Flex direction="column" gap="4">
        {title && <Text fontWeight="semiBold">{title}</Text>}
        {children}
      </Flex>
    </Container>
  );
};
