import { ResourceItemComment } from "@wayflyer/flyui";
import type { FundingApplicationSchemas } from "api-types";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import type { IntlShape } from "react-intl";

import {
  formatRevenueAmount,
  getEndDateForPeriodText,
  getMissingPeriodString,
  isInsufficientL60dRevenueAIR,
} from "../../..";
import { MESSAGES } from "../message-definitions";

import { AirConnectionResponseItems, commentTruncation } from "./shared";

const noPlatformReasonCodeToLabelMap: { [key: string]: { id: string; defaultMessage: string } } = {
  company_less_than_6m_old: MESSAGES.reasonLessThan180DaysCompanyOption,
  store_decommissioned: MESSAGES.reasonPreviousCompanyDecommissionedOption,
  no_other_revenue_sources: MESSAGES.reasonNoOtherPlatformsOption,
};

dayjs.extend(LocalizedFormat);

export const getAdditionalRevenueSourceDetailsText = (
  intl: IntlShape,
  airData: FundingApplicationSchemas.AdditionalRevenueSourceAIRSchema
) => {
  if (
    airData.reasons.includes("insufficient_revenue_data") &&
    airData.reasons.includes("customer_revenue_not_sufficient_for_funding")
  ) {
    return intl.formatMessage(
      {
        id: "shared-ui: air revenue source mixed insufficient revenue details",
        defaultMessage:
          "For {startPeriod} to {endPeriod} and with average monthly revenue of over {thresholdAmount} over the last {period}",
      },
      {
        startPeriod: dayjs(airData.missing_period?.start).format("LL"),
        endPeriod: getEndDateForPeriodText(airData.missing_period?.end).format("LL"),
        period: getMissingPeriodString(airData.missing_period, intl),
        thresholdAmount: formatRevenueAmount(airData.mean_30d_revenue_threshold, intl),
      }
    );
  }
  if (airData.reasons.includes("customer_revenue_not_sufficient_for_funding")) {
    return intl.formatMessage(
      {
        id: "shared-ui: air revenue source insufficient revenue details",
        defaultMessage: "Average monthly revenue of over {thresholdAmount} over the last {insufficientPeriod} months",
      },
      {
        thresholdAmount: formatRevenueAmount(airData.mean_30d_revenue_threshold, intl),
        insufficientPeriod: isInsufficientL60dRevenueAIR(airData) ? 2 : 6,
      }
    );
  }
  if (airData.reasons.includes("insufficient_revenue_data")) {
    return intl.formatMessage(MESSAGES.airTitlePeriodsText, {
      startPeriod: dayjs(airData.missing_period?.start).format("LL"),
      endPeriod: dayjs(airData.missing_period?.end).format("LL"),
    });
  }
};

export const getAdditionalRevenueResponseChildren = (
  intl: IntlShape,
  connectionMetaData: FundingApplicationSchemas.ConnectionMetadataSchema[],
  airResponse: FundingApplicationSchemas.AdditionalRevenueSourceAIRResponse
) => {
  const _commentTruncation = commentTruncation(intl);
  // Connected supported platform
  if (airResponse.connection_ids.length > 0) {
    return <AirConnectionResponseItems connectionMetaData={connectionMetaData} />;
  }
  // Submitted unsupported platform
  if (airResponse.no_other_connections_reason === "unsupported_store" && airResponse.unsupported_source_name) {
    return <ResourceItemComment truncation={_commentTruncation} text={airResponse.unsupported_source_name ?? ""} />;
  }
  // Submitted "other" free text response
  if (airResponse.no_other_connections_reason === "other" && airResponse.reason) {
    return <ResourceItemComment truncation={_commentTruncation} text={airResponse.reason ?? ""} />;
  }
  // Submitted reason code
  if (airResponse.no_other_connections_reason != null) {
    const message = noPlatformReasonCodeToLabelMap[airResponse.no_other_connections_reason] ?? "";
    return <ResourceItemComment truncation={_commentTruncation} text={message ? intl.formatMessage(message) : ""} />;
  }
};
