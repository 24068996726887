import * as React from "react";
import { Spinner } from "@wayflyer/flyui";
import { styled } from "styled-components";

const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const FullScreenLoader = () => {
  return (
    <FullScreenContainer>
      <Spinner size={64} />
    </FullScreenContainer>
  );
};
