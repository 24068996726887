import { http, HttpResponse } from "msw";

export const getDisqualifiedApplicationHandler = {
  insufficientRevenue: http.get("*/financing/underwriting/decision-status", () => {
    return HttpResponse.json({
      reapplicationEligibleDate: "2025-04-26",
      messageParam: null,
      overrideMessage: `
        <p>Thank you for your interest in Wayflyer funding. We appreciate you taking the time to submit your application.</p>\n<p>One of our key eligibility requirements for funding is that businesses in your region demonstrate an average monthly\n    revenue of AUD12,500 over the past\n    6 months. Based on our review, your business currently shows an average monthly\n    revenue of AUD6,664 over the last\n    6 months.</p>\n<p>Unfortunately, this falls below our current threshold, and we are therefore unable to approve your application at\n    this time. However, if you can demonstrate a consistent average monthly revenue of\n    AUD12,500 over the next 6 months,\n    we would be happy to re-evaluate your eligibility for Wayflyer funding.</p>\n\nYou will be able to reapply for funding on 24 June, 2024 if your circumstances have changed.\n\nIn the meantime, your connected platforms will enable you to utilise Wayflyer's [performance insights](/wb78ff04/performance/) for free to improve your position.
        `,
    });
  }),
  insufficientRevenueMessageParam: http.get("*/financing/underwriting/decision-status", () => {
    return HttpResponse.json({
      reapplicationEligibleDate: "2025-04-26",
      overrideMessage: null,
      messageParam: "26 April, 2025",
    });
  }),
};
