import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0SessionRefresher } from "@wayflyer/auth-sdk";

import { getWfAuth0Config } from "./config";

export interface WfAuth0ProviderProps {
  clientId: string;
  domain: string;

  children?: React.ReactNode;
}

export const WfAuth0Provider = (props: WfAuth0ProviderProps) => {
  const withSessionRefresher = process.env.NODE_ENV !== "test";

  return (
    <Auth0Provider
      authorizationParams={{
        audience: "wayflyer",
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      clientId={props.clientId}
      domain={props.domain}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      skipRedirectCallback={true}
    >
      {withSessionRefresher ? <Auth0SessionRefresher /> : null}
      {props.children}
    </Auth0Provider>
  );
};

export function WfAuth0ProviderFromViteConfig({ children }: { children: React.ReactNode }) {
  const config = getWfAuth0Config();

  return (
    <WfAuth0Provider clientId={config.clientId} domain={config.domain}>
      {children}
    </WfAuth0Provider>
  );
}
