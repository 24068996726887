import { getVariantFromError } from "@wayflyer/blackbox-fe";
import { useIntl } from "react-intl";
import { createBrowserRouter, Outlet, useRouteError } from "react-router";
import { ErrorComponent, getCustomerErrorPresetMap } from "shared-ui";

import { RootComponent } from "./libs/app/renderRootComponent";

const ErrorElement = () => {
  const error = useRouteError();
  const variant = getVariantFromError(error);
  const intl = useIntl();
  const { title, message, primaryAction } = getCustomerErrorPresetMap({ intl })[variant];

  return (
    <ErrorComponent
      {...{
        title,
        message,
        primaryAction,
        secondaryAction: {
          content: intl.formatMessage({ id: "error.goBack", defaultMessage: "Go back" }),
          onAction: () => window.history.back(),
        },
      }}
    />
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    handle: {
      title: "Wayflyer",
    },
    element: (
      <RootComponent>
        <Outlet />
      </RootComponent>
    ),
    errorElement: (
      <RootComponent>
        <ErrorElement />
      </RootComponent>
    ),
    children: [
      {
        path: "/",
        lazy: () => import("./entry-points/home"),
        handle: {
          title: "Home",
        },
      },
      {
        // TODO: things under /auth will be handled on the server side
        // and as such, should not render UI, just redirect
        path: "/auth",
        children: [
          {
            path: "bigcommerce/load",
            lazy: () => import("./integrations/bigcommerce-iframe/bigcommerce-load"),
            handle: {
              title: "Wayflyer for BigCommerce",
            },
          },
          {
            path: "bigcommerce/callback",
            lazy: () => import("./integrations/bigcommerce-iframe/bigcommerce-callback"),
            handle: {
              title: "Wayflyer for BigCommerce",
            },
          },
        ],
      },
      {
        path: "/authn-wf/callback",
        lazy: () => import("./authn/callback"),
      },
      {
        path: "/authn-wf/callback-transaction",
        lazy: () => import("./authn/callback-transaction"),
      },
      // ===> Local development only: uncomment/see for example of authn utils.
      // {
      //   path: "/authn-wf/example",
      //   lazy: () => import("./authn/example"),
      // },
      // {
      //   path: "/authn-wf/example-hook",
      //   lazy: () => import("./authn/example-hook"),
      // },
      // {
      //   path: "/authn-wf/example-transaction",
      //   lazy: () => import("./authn/example-transaction"),
      // },
      // <======================================================================
      {
        path: "/benchmarks-signup",
        lazy: async () => {
          const { Component } = await import("./product-choice");
          return {
            Component: () => <Component product="benchmarks" />,
          };
        },
        handle: {
          title: "Benchmarks Signup",
        },
      },
      {
        path: "/benchmarks/*",
        lazy: () => import("./entry-points/benchmarks/app"),
        handle: {
          title: "Benchmarks",
        },
      },
      {
        path: "/beta-signup-wfbank",
        lazy: async () => {
          const { Component } = await import("./product-choice");
          return {
            Component: () => <Component product="banking" />,
          };
        },
        handle: {
          title: "Banking",
        },
      },
      {
        path: "/company-creation/*",
        lazy: () => import("./entry-points/company_creation/app"),
        handle: {
          title: "Company Details",
        },
      },
      {
        path: "/customer-referrals",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Customer Referrals",
        },
      },
      {
        path: "/financial-os-banking",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Financial OS Banking",
        },
      },
      {
        path: "/financing/ach-stripe",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "ACH Stripe",
        },
      },
      {
        path: "/financing/bank-statements",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Upload Bank Statements",
        },
      },
      {
        path: "/financing/bank-statements/connect",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Connect Bank Statements",
        },
      },
      {
        path: "/financing/bank-statements/connect/basiq",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Connect Bank Statements via Basiq",
        },
      },
      {
        path: "/financing/bank-statements/upload",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Upload Bank Statements",
        },
      },
      {
        path: "/financing/funds-request",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Funds Request",
        },
      },
      {
        path: "/funding/how-to-apply",
        lazy: () => import("./entry-points/funding_application/how-to-apply-app"),
        handle: {
          title: "Apply for Funding",
        },
      },
      {
        path: "/settings-connections",
        lazy: async () => {
          const { Component } = await import("./authn/authenticated-customer-route");
          return {
            Component: () => <Component alternativePath="/settings/connections" />,
          };
        },
        handle: {
          title: "Connect your store",
        },
      },
      {
        path: "/settings/connections/connect-accounting",
        lazy: () => import("./authn/authenticated-customer-route"),
        handle: {
          title: "Connect your store",
        },
      },
      {
        path: "/small-business-lending",
        lazy: async () => {
          const { Component } = await import("./product-choice");
          return {
            Component: () => <Component product="small-business-lending" />,
          };
        },
        handle: {
          title: "Small Business Lending",
        },
      },
      {
        path: "/upload-documents",
        lazy: async () => {
          const { Component } = await import("./authn/authenticated-customer-route");
          return {
            Component: () => <Component alternativePath="/funding/upload-documents" />,
          };
        },
        handle: {
          title: "Upload Documents",
        },
      },
      {
        path: "/verify-identity/:idv_token",
        lazy: () => import("./entry-points/kyc/verify-identity"),
        handle: {
          title: "Verify your identity",
        },
      },
      {
        path: "/wholesale",
        lazy: async () => {
          const { Component } = await import("./product-choice");
          return {
            Component: () => <Component product="wholesale" />,
          };
        },
        handle: {
          title: "Wholesale",
        },
      },
      {
        path: "/:customerDomain/?",
        lazy: () => import("./entry-points/home/customer-home"),
        handle: {
          title: "Home",
        },
      },
      {
        path: "/:customerDomain/banking/",
        lazy: () => import("./banking/banking-screen/banking-screen"),
        handle: {
          title: "Banking",
        },
      },
      {
        path: "/:customerDomain/banking/mfa",
        lazy: () => import("./banking/banking-mfa-setup-screen/banking-mfa-setup"),
        handle: {
          title: "Banking",
        },
      },
      {
        path: "/:customerDomain/banking/banking-whitelabel",
        lazy: () => import("./banking/whitelabel-screen/whitelabel"),
        handle: {
          title: "Banking",
        },
      },
      {
        path: "/:customerDomain/banking/*",
        lazy: () => import("./entry-points/banking-app/app"),
        handle: {
          title: "Banking",
        },
      },
      {
        path: "/:customerDomain/benchmarks-signup",
        lazy: () => import("./entry-points/benchmarks/benchmarks-signup"),
        handle: {
          title: "Benchmarks Signup",
        },
      },
      {
        path: "/:customerDomain/benchmarks/*",
        lazy: () => import("./entry-points/benchmarks/app"),
        handle: {
          title: "Benchmarks",
        },
      },
      {
        path: "/:customerDomain/beta-signup-wfbank",
        lazy: () => import("./entry-points/banking-app/bank-beta-signup"),
        handle: {
          title: "Banking",
        },
      },
      {
        path: "/:customerDomain/connections",
        lazy: () => import("./settings/connections-redirect"),
        handle: {
          title: "Connect your store",
        },
      },
      {
        path: "/:customerDomain/customer-referrals",
        lazy: () => import("./customer-referrals/customer-referrals-screen/customer-referrals-screen"),
        handle: {
          title: "Customer Referrals",
        },
      },
      {
        path: "/:customerDomain/tasks",
        lazy: () => import("./customer-tasks/customer-tasks"),
        handle: {
          title: "Tasks",
        },
      },
      {
        path: "/:customerDomain/documents/",
        lazy: () => import("./documents/documents-screen"),
        handle: {
          title: "Documents",
        },
      },
      {
        path: "/:customerDomain/financing-contract-order-form",
        lazy: () => import("./financing/contracts/screens/mca-order-form-screen/mca-order-form-screen"),
        handle: {
          title: "Financing Contract Order Form",
        },
      },
      {
        path: "/:customerDomain/financing-contract-disclosures",
        lazy: () => import("./financing/contracts/screens/disclosure-screen/disclosure-screen"),
        handle: {
          title: "Financing Contract Disclosures",
        },
      },
      {
        path: "/:customerDomain/financing-contract-bank-details",
        lazy: () => import("./financing/contracts/screens/bank-account-info-screen/bank-account-info-screen"),
        handle: {
          title: "Financing Contract Bank Details",
        },
      },
      {
        path: "/:customerDomain/financing",
        lazy: () => import("./entry-points/financing/app"),
        handle: {
          title: "Financing",
        },
      },
      {
        path: "/:customerDomain/financing/advances",
        lazy: () => import("./financing/financing"),
        handle: {
          title: "Financing",
        },
      },
      {
        path: "/:customerDomain/financing/ach-stripe",
        lazy: () => import("./financing/bank-connect/ach-stripe/ach-stripe"),
      },
      {
        path: "/:customerDomain/financing/bank-statements",
        lazy: () => import("./financing/upload-bank-statements"),
        handle: {
          title: "Upload your bank statements",
        },
      },
      {
        path: "/:customerDomain/financing/bank-statements/connect",
        lazy: () => import("./financing/upload-bank-statements"),
        handle: {
          title: "Upload your bank statements",
        },
      },
      {
        path: "/:customerDomain/financing/bank-statements/upload",
        lazy: () => import("./financing/bank-statements-upload/bank-statements-upload-screen"),
        handle: {
          title: "Upload your bank statements",
        },
      },
      {
        path: "/:customerDomain/financing/direct-debit/:ppAccountName",
        lazy: () => import("./financing/direct-debit/direct-debit"),
        handle: {
          title: "Upload your bank statements",
        },
      },
      {
        path: "/:customerDomain/financing/funds-request/*",
        lazy: () => import("./financing/funds-request"),
        handle: {
          title: "Financing",
        },
      },
      {
        path: "/:customerDomain/financing/statements",
        lazy: () => import("./financing/statements"),
        handle: {
          title: "Generate transaction statement",
        },
      },
      {
        path: "/:customerDomain/financing/write-back",
        lazy: () => import("./financing/write-back/write-back"),
        handle: {
          title: "Xero Sync Integration",
        },
      },
      {
        path: "/:customerDomain/funding/application/*",
        lazy: () => import("./entry-points/funding_application/app"),
        handle: {
          title: "Apply for Funding",
        },
      },
      {
        path: "/:customerDomain/funding/application/configure-new-funder-customer",
        lazy: () => import("./entry-points/funding_application/configure-new-funder-customer"),
        handle: {
          title: "Configure Funder",
        },
      },
      {
        path: "/:customerDomain/invites/grant-access/:requestToken",
        lazy: () => import("./entry-points/company_creation/invite-request"),
        handle: {
          title: "Invite Requests",
        },
      },
      {
        path: "/:customerDomain/performance",
        lazy: () => import("./entry-points/insights/overview-dashboard"),
        handle: {
          title: "Performance - Overview",
        },
      },
      {
        path: "/:customerDomain/performance/channels",
        lazy: () => import("./entry-points/insights/channels"),
        handle: {
          title: "Performance - Channels",
        },
      },
      {
        path: "/:customerDomain/performance/googleads",
        lazy: () => import("./entry-points/insights/googleads"),
        handle: {
          title: "Performance - Google Ads",
        },
      },
      {
        path: "/:customerDomain/performance/int-overview",
        lazy: () => import("./entry-points/insights/internal-overview-dashboard"),
        handle: {
          title: "Performance - Overview",
        },
      },
      {
        path: "/:customerDomain/performance/ltv",
        lazy: () => import("./entry-points/insights/consumers"),
        handle: {
          title: "Lifetime Value",
        },
      },
      {
        path: "/:customerDomain/performance/ltv-cac",
        lazy: () => import("./entry-points/insights/internal-ltv-cac"),
        handle: {
          title: "Performance - LTV-CAC",
        },
      },
      {
        path: "/:customerDomain/performance/metaads",
        lazy: () => import("./entry-points/insights/meta-ads"),
        handle: {
          title: "Performance - Meta Ads",
        },
      },
      {
        path: "/:customerDomain/performance/facebook",
        lazy: () => import("./entry-points/insights/facebook"),
        handle: {
          title: "Performance - Meta Ads",
        },
      },
      {
        path: "/:customerDomain/performance/paid-channel-summary",
        lazy: () => import("./entry-points/insights/paid-channel-summary"),
        handle: {
          title: "Paid Channels",
        },
      },
      {
        path: "/:customerDomain/performance/products",
        lazy: () => import("./entry-points/insights/products"),
        handle: {
          title: "Performance - Products",
        },
      },
      {
        path: "/:customerDomain/performance/revenue",
        lazy: () => import("./entry-points/insights/internal-revenue"),
        handle: {
          title: "Performance - Revenue",
        },
      },
      {
        path: "/:customerDomain/performance/tiktok-spend",
        lazy: () => import("./entry-points/insights/internal-tiktok-ad-spend"),
        handle: {
          title: "Performance - TikTok Ad Spend",
        },
      },
      {
        path: "/:customerDomain/remediation",
        lazy: () => import("./entry-points/company_creation/remediation"),
        handle: {
          title: "Company Remediation",
        },
      },
      {
        path: "/:customerDomain/settings",
        lazy: () => import("./entry-points/profile_spaces/settings-profile"),
        handle: {
          title: "Settings",
        },
      },
      {
        path: "/:customerDomain/small-business-lending",
        lazy: () => import("./entry-points/small-business-lending/app"),
        handle: {
          title: "Small Business Lending",
        },
      },
      {
        path: "/:customerDomain/settings/connections/*",
        lazy: () => import("./settings/app"),
        handle: {
          title: "Connect your store",
        },
      },
      {
        path: "/:customerDomain/verify-business",
        lazy: () => import("./entry-points/kyc/verify-business"),
        handle: {
          title: "Verify your business",
        },
      },
      {
        path: "/:customerDomain/wholesale",
        lazy: () => import("./entry-points/wholesale/app"),
        handle: {
          title: "Wholesale",
        },
      },
      {
        path: "*",
        lazy: () => import("./errors/error404"),
        handle: {
          title: "Page not found",
        },
      },
    ],
  },
]);
