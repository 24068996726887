import * as React from "react";

export type UploadStatus = "" | "uploading" | "complete";

export type BaseUploadedFile = { name: string };

type Upload<T> = {
  status: "queued" | "uploading" | "complete" | "failed";
  error?: string;
  file: File;
  uploadedFile?: T;
};

export type UploadsState<T> = {
  uploadsStarted: boolean;
  uploadsComplete: boolean;
  uploads: Upload<T>[];
};

type FilesAddedAction = {
  type: "filesAdded";
  payload: {
    files: File[];
  };
};

type FileRemovedAction = {
  type: "fileRemoved";
  payload: {
    file: File;
  };
};
type FileDownloadAction = {
  type: "fileDownload";
  payload: {
    file: File;
  };
};

type UploadStartedAction = {
  type: "uploadStarted";
  payload: {
    file: File;
  };
};

type UploadFinishedAction<T> = {
  type: "uploadFinished";
  payload: {
    file: File;
    uploadedFile: T;
  };
};

type UploadFailedAction = {
  type: "uploadFailed";
  payload: {
    file: File;
    error: string;
  };
};

type RemoveFailedAction = {
  type: "removeFailed";
  payload: {
    file: File;
    error: string;
  };
};

type ResetAction = {
  type: "reset";
};

type MultiFileUploadAction<T> =
  | FilesAddedAction
  | FileRemovedAction
  | UploadStartedAction
  | UploadFinishedAction<T>
  | UploadFailedAction
  | RemoveFailedAction
  | ResetAction
  | FileDownloadAction;

export function useMultiFileUploadReducer<T extends BaseUploadedFile>(files?: T[]) {
  const initialState: UploadsState<T> = {
    uploadsStarted: false,
    uploadsComplete: false,
    uploads: files
      ? files.map((uploadedFile) => ({ status: "complete", file: new File([], uploadedFile.name), uploadedFile }))
      : [],
  };

  const reducer = (state: UploadsState<T>, action: MultiFileUploadAction<T>): UploadsState<T> => {
    switch (action.type) {
      case "filesAdded": {
        const uploads: Upload<T>[] = action.payload.files.map((file) => {
          return { status: "queued", file };
        });
        return { ...state, uploadsComplete: false, uploads: state.uploads.concat(uploads) };
      }
      case "fileRemoved": {
        return { ...state, uploads: state.uploads.filter((upload) => upload.file !== action.payload.file) };
      }
      case "uploadStarted": {
        return {
          ...state,
          uploadsStarted: true,
          uploads: state.uploads.map((upload) =>
            upload.file !== action.payload.file ? upload : { file: action.payload.file, status: "uploading" }
          ),
        };
      }
      case "uploadFinished": {
        const newState: UploadsState<T> = {
          ...state,
          uploadsStarted: true,
          uploads: state.uploads.map((upload) =>
            upload.file !== action.payload.file
              ? upload
              : { file: action.payload.file, uploadedFile: action.payload.uploadedFile, status: "complete" }
          ),
        };
        if (newState.uploads.every((upload) => upload.status !== "uploading")) {
          newState.uploadsComplete = true;
        }
        return newState;
      }
      case "uploadFailed": {
        const newState: UploadsState<T> = {
          ...state,
          uploadsStarted: true,
          uploads: state.uploads.map((upload) =>
            upload.file !== action.payload.file
              ? upload
              : { file: action.payload.file, status: "failed", error: action.payload.error }
          ),
        };
        if (newState.uploads.every(({ status }) => status === "complete" || status === "failed")) {
          newState.uploadsComplete = true;
        }
        return newState;
      }
      case "removeFailed": {
        return {
          ...state,
          uploads: state.uploads.map((upload) =>
            upload.file !== action.payload.file
              ? upload
              : {
                  file: action.payload.file,
                  uploadedFile: upload.uploadedFile,
                  status: "failed",
                  error: action.payload.error,
                }
          ),
        };
      }
      case "reset": {
        return initialState;
      }
      default: {
        return state;
      }
    }
  };
  return React.useReducer(reducer, initialState);
}
