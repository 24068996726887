import { addError } from "@wayflyer/blackbox-fe";

import { pathWithoutCustomerSlug } from "./location";
import { emailMask } from "./email-mask";

declare global {
  interface Window {
    dataLayer: Array<any>;
    google_tag_manager: any;
  }
}

window.dataLayer = window.dataLayer || [];

type AnalyticsAttributes = {
  userId?: number;
  companyPublicId?: string;
  isStaff: boolean;
  isTest: boolean;
  page_location: string;
  page_referrer: string;
};

/*
These are accessed directly by Google Tag Manager, before changing them read analytics_and_tag_manager.md in docs
 */
function getAnalyticsAttributes(context: any = {}): AnalyticsAttributes {
  return {
    userId: context.userId || null,
    companyPublicId: context.companyPublicId || null,
    isStaff: context.isStaff || false,
    isTest: context.isTest || false,
    page_location: pathWithoutCustomerSlug(window.location.pathname),
    page_referrer: getScrubbedReferrer(),
  };
}

function getScrubbedReferrer() {
  if (document.referrer.length > 0) {
    try {
      return pathWithoutCustomerSlug(new URL(document.referrer).pathname);
    } catch (e) {
      // this shouldn't ever happen.
      // The referrer should either be empty or a valid url
      addError(e);
      return "";
    }
  }
  return "";
}

export const pushEvent = (context: any = {}, name: string, attrs: any = {}) => {
  // Push event into datalayer, to be picked up by Google Tag Manager to end up in GA
  const analyticsAttributes = getAnalyticsAttributes(context);
  if (attrs.message && typeof attrs.message === "string") {
    attrs.message = emailMask(attrs.message);
  }
  const event = {
    event: name,
    ...analyticsAttributes,
    ...attrs,
  };
  window.dataLayer.push(event);
};
