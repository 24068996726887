import { lazy } from "react";

// Errors, leaving these outside of the new routing patterns for now
const ErrorBadLink = lazy(() => import("./errors/errorBadLink"));
const Error403 = lazy(() => import("./errors/error403"));
const Error404 = lazy(() => import("./errors/error404"));
const Error500 = lazy(() => import("./errors/error500"));
const ErrorAuthnEmailConstraint = lazy(() => import("./errors/errorAuthnEmailConstraint"));

export const ERROR_COMPONENTS: { [keyof: string]: React.ComponentType<any> } = {
  ErrorBadLink,
  Error403,
  Error404,
  Error500,
  ErrorAuthnEmailConstraint,
};
