import { defineMessages } from "react-intl";

const BANK_STATEMENTS_MESSAGES = defineMessages({
  airDetailsPeriodsText: {
    id: "shared-ui: air bank statements required details for periods",
    defaultMessage: "for {startPeriod} to {endPeriod}",
  },
  airBankStatementsText: {
    id: "shared-ui: air bank statements required",
    defaultMessage: "Upload your bank statements [Ref: {refNumber}]",
    description: "Heading for banking air resource items. 'Ref' refers to the reference number of the request.",
  },
  airBankStatementsDetailsText: {
    id: "shared-ui: air bank statements required details",
    defaultMessage: "{bankName} account ending in {accountNum}, {periods}",
  },
  airBankStatementsDetailsTextNoMask: {
    id: "shared-ui: air bank statements required details no mask",
    defaultMessage: "{bankName} account(s), {periods}",
  },
  defaultDetailsTextBankName: {
    id: "shared-ui: air bank statements required text details default bank name",
    defaultMessage: "Bank",
    description: "In the case where we do not have a bank name e.g. 'Chase', refer to as 'Bank'",
  },
});

const REVENUE_SOURCE_MESSAGES = defineMessages({
  airRevenueSourceText: {
    id: "shared-ui: air revenue source required text",
    defaultMessage: "Additional eCommerce connection required [Ref: {refNumber}]",
    description: "Heading for additional air resource items. 'Ref' refers to the reference number of the request.",
  },
  reasonLessThan180DaysCompanyOption: {
    id: "shared-ui: reason air revenue source less than 6 months company option",
    defaultMessage: "My eCommerce company is less than 6 months old",
  },
  reasonPreviousCompanyDecommissionedOption: {
    id: "shared-ui: reason air revenue source previous company decommissioned option",
    defaultMessage: "My previous eCommerce company has been decommissioned",
  },
  reasonNoOtherPlatformsOption: {
    id: "shared-ui: reason air revenue source no other platforms option",
    defaultMessage: "I don’t have other eCommerce platforms",
  },
});

const RECONNECT_CONNECTION_MESSAGES = defineMessages({
  airReconnectConnectionText: {
    id: "shared-ui: air reconnect connection required text",
    defaultMessage: "Reconnect your {platform} [Ref: {refNumber}]",
    description:
      "Heading for reconnect air resource items. 'platform' refers to the specific connection type e.g. 'eCommerce platform'. 'Ref' refers to the reference number of the request.",
  },
  airConnectedPlatformWithIdentifier: {
    id: "shared-ui: air reconnect platform with identifier",
    defaultMessage: "Connected {connectorName} platform: {connectorIdentifier}",
    description: "Info about which platform was connected as part of air response (includes identifier)",
  },
  airConnectedPlatformWithoutIdentifier: {
    id: "shared-ui: air reconnect platform without identifier",
    defaultMessage: "Connected {connectorName} platform",
    description: "Info about which platform was connected as part of air response (includes identifier)",
  },
});

export const MESSAGES = defineMessages({
  periodText: {
    id: "shared-ui: period text",
    defaultMessage: "{dateFrom} to {dateTo}",
  },
  showMoreLabel: {
    id: "shared-ui: show more label",
    defaultMessage: "Show more",
  },
  showLessLabel: {
    id: "shared-ui: show less label",
    defaultMessage: "Show less",
  },
  additionalInformationRequestedTitle: {
    id: "shared-ui: default additional information title",
    defaultMessage: "Additional information requested [Ref: {refNumber}]",
  },
  airStatusSupercededResponseText: {
    id: "shared-ui: air superceded response text",
    defaultMessage: "This request has been completed automatically. No further action is required at this time.",
  },
  airTitlePeriodsText: {
    id: "shared-ui: air for periods required title",
    defaultMessage: "For {startPeriod} to {endPeriod}",
  },
  airStatusCompleted: {
    id: "shared-ui: air completed",
    defaultMessage: "Completed",
  },
  airCTAViewRequest: {
    id: "shared-ui: air cta view request",
    defaultMessage: "View request",
  },
  airStatusSubmitted: {
    id: "shared-ui: air submitted",
    defaultMessage: "Submitted",
  },
  reasonPreviousPlatformDecommissionedOption: {
    id: "shared-ui: reason air reconnect platform decommissioned option",
    defaultMessage: "My previous {platformType} has been decommissioned",
    description:
      "Reason option for when the previous platform has been decommissioned. Platform type is the specific type of platform e.g. 'eCommerce' or 'Marketing.",
  },
  ...BANK_STATEMENTS_MESSAGES,
  ...REVENUE_SOURCE_MESSAGES,
  ...RECONNECT_CONNECTION_MESSAGES,
});
