const previewFlag = "wf-refresh-preview";

export const setRefreshPreview = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const paramValue = searchParams.get("refresh-preview");

  if (paramValue === "on") {
    localStorage.setItem(previewFlag, "on");
  } else if (paramValue === "off") {
    localStorage.removeItem(previewFlag);
  }

  return { previewEnabled: localStorage.getItem(previewFlag) === "on" };
};
