import { renderRootComponent, renderErrorComponent } from "./libs/app/renderRootComponent";
import { ERROR_COMPONENTS } from "./root-components";

type ReactPageArgs = {
  componentName: string;
  props: any;
};

let reactPageArgs = null;

function getReactPageArgs(): ReactPageArgs {
  if (reactPageArgs !== null) {
    return reactPageArgs;
  }
  reactPageArgs = JSON.parse(document.getElementById("render-args").textContent);
  return reactPageArgs;
}

export function renderReactPage() {
  const pageArgs = getReactPageArgs();
  if (Object.keys(ERROR_COMPONENTS).includes(pageArgs.componentName)) {
    renderErrorComponent(ERROR_COMPONENTS[pageArgs.componentName], pageArgs.props);
  } else {
    renderRootComponent(pageArgs.props);
  }
}
