import { FundingApplicationSchemas } from "api-types";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import type { IntlShape } from "react-intl";

dayjs.extend(duration);

export const isInsufficientL60dRevenueAIR = (airData: FundingApplicationSchemas.AdditionalRevenueSourceAIRSchema) => {
  const { mean_monthly_l180d_revenue, mean_30d_revenue_threshold } = airData;
  return (
    (mean_monthly_l180d_revenue?.amount_in_lowest_denomination ?? 0) >=
    (mean_30d_revenue_threshold?.amount_in_lowest_denomination ?? 0)
  );
};

export const getMissingPeriod = (period: FundingApplicationSchemas.DateTimeRangeSchema): duration.Duration => {
  const referenceDate = dayjs(period.start);
  const endDate = period.end ? dayjs(period.end) : dayjs();
  return dayjs.duration(endDate.diff(referenceDate));
};

export const getRecievedPeriod = (period: FundingApplicationSchemas.DateTimeRangeSchema): duration.Duration => {
  const sixMonths = dayjs.duration({ months: 6 });
  const missingPeriod = getMissingPeriod(period);

  return sixMonths.subtract(missingPeriod);
};

export const getMissingPeriodString = (
  period: FundingApplicationSchemas.DateTimeRangeSchema | null | undefined,
  intl: IntlShape
): string => {
  if (!period) {
    return intl.formatMessage(
      {
        id: "shared-ui: air revenue source period months",
        defaultMessage: "{period, plural, =1 {# month} other {# months}}",
      },
      { period: 0 }
    );
  }
  const periodDuration = getMissingPeriod(period);

  const periodInMonths = periodDuration.asMonths();
  if (periodInMonths >= 1) {
    return intl.formatMessage(
      {
        id: "shared-ui: air revenue source period months",
        defaultMessage: "{period, plural, =1 {# month} other {# months}}",
      },
      { period: Math.round(periodInMonths) }
    );
  }

  const periodInWeeks = periodDuration.asWeeks();
  return intl.formatMessage(
    {
      id: "shared-ui: air revenue source period weeks",
      defaultMessage: "{period, plural, =1 {# week} other {# weeks}}",
    },
    { period: Math.round(periodInWeeks) }
  );
};
