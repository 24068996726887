import { ResourceItemSimpleList, type TaskResourceItemProps } from "@wayflyer/flyui";
import { IconArrowsRightLeft24Line } from "@wayflyer/flyui-icons/24/line";
import type { FundingApplicationSchemas } from "api-types";
import { useIntl, type IntlShape } from "react-intl";

import { getConnectorNameForFE, getPlatformStringFromConnectionCategory } from "../../common";
import { MESSAGES } from "../message-definitions";

export type TaskResourceItemPropsWithId = TaskResourceItemProps & { id: string };

export const commentTruncation = (intl: IntlShape) => ({
  charLimit: 140,
  showLessText: intl.formatMessage(MESSAGES.showLessLabel),
  showMoreText: intl.formatMessage(MESSAGES.showMoreLabel),
});

export const listTruncation = (intl: IntlShape) => ({
  limit: 5,
  showLessText: intl.formatMessage(MESSAGES.showLessLabel),
  showMoreText: intl.formatMessage(MESSAGES.showMoreLabel),
});

export const getAirItemLabel = (
  intl: IntlShape,
  air: FundingApplicationSchemas.AdditionalInformationRequestWithMetaSchema
): string => {
  const { public_identifier: reference, type } = air;
  switch (type) {
    case "bank_statements_for_account":
      return intl.formatMessage(MESSAGES.airBankStatementsText, { refNumber: reference });
    case "additional_revenue_source":
      return intl.formatMessage(MESSAGES.airRevenueSourceText, { refNumber: reference });
    case "reconnect_connection": {
      const reconnectAirData = air.data as FundingApplicationSchemas.ReconnectConnectionAIRSchema;
      return intl.formatMessage(MESSAGES.airReconnectConnectionText, {
        platform: getPlatformStringFromConnectionCategory(
          intl,
          reconnectAirData.connection_meta_data?.type,
          reconnectAirData.connection_type
        ),
        refNumber: air.public_identifier,
      });
    }
    default:
      return intl.formatMessage(MESSAGES.additionalInformationRequestedTitle, { refNumber: reference });
  }
};

export const AirConnectionResponseItems = ({
  connectionMetaData,
}: {
  connectionMetaData: FundingApplicationSchemas.ConnectionMetadataSchema[];
}) => {
  const intl = useIntl();
  const items = connectionMetaData.map((meta) => ({
    icon: <IconArrowsRightLeft24Line />,
    text: meta.identifier
      ? intl.formatMessage(MESSAGES.airConnectedPlatformWithIdentifier, {
          connectorName: getConnectorNameForFE(meta.connectorName),
          connectorIdentifier: meta.identifier,
        })
      : intl.formatMessage(MESSAGES.airConnectedPlatformWithoutIdentifier, {
          connectorName: getConnectorNameForFE(meta.connectorName),
        }),
  }));
  return items ? <ResourceItemSimpleList truncation={listTruncation(intl)} items={items} /> : null;
};
