import { Button, Flex, type FlexProps, useDetectDeviceSize, type Action } from "@wayflyer/flyui";
import { AnimatePresence, type AnimationProps, LayoutGroup, motion } from "framer-motion";
import type { PropsWithChildren } from "react";
import { useCallback } from "react";
import type { SwipeableHandlers } from "react-swipeable";
import { styled, css } from "styled-components";

import { useQueryParam } from "../../hooks";

type CarouselAnimationProps = {
  variants: Record<string, any>;
  initial: AnimationProps["initial"];
  animate: AnimationProps["animate"];
  exit: AnimationProps["exit"];
  custom: number;
  transition: Record<string, any>;
  onAnimationStart?: () => void;
  onAnimationComplete?: () => void;
};

export type CarouselAction = {
  content: Action["content"];
  onAction: Action["onAction"];
  analyticsId?: Action["analyticsId"];
};

export type CarouselActions = {
  primaryAction: Action;
  secondaryAction?: Action;
  tertiaryAction?: Action;
};

export type CarouselProps = CarouselActions & {
  stepNumber: number;
  totalSteps: number;
  swipeHandlers?: SwipeableHandlers;
} & CarouselAnimationProps;

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Pill = styled.div<{ active: boolean; height: string; width: string; color?: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.buttons.primary.surface.default};
  margin-right: ${({ theme }) => theme.spacing("1")};

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.3;
    `}
`;

const Container = styled.div`
  width: 100%;
  gap: ${({ theme }) => theme.spacing("8")};
  ${flexColumnCss};
`;

const CarouselContent = styled(motion.div)`
  ${flexColumnCss};
`;

const ButtonsContainer = styled(motion.div)<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.spacing("4")};
  padding: 0 ${({ theme }) => theme.spacing("16")};
  @media (max-width: ${({ theme }) => theme.breakpoint("sm")}) {
    padding: 0 ${({ theme }) => theme.spacing("6")};
  }
  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoint("sm")}) {
        flex-direction: row;
      }
    `};
`;

const ButtonContainer = styled.div<{ fullWidth?: boolean }>`
  ${flexColumnCss};
  width: 100%;
  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoint("sm")}) {
        width: 50%;
      }
    `};
`;

const TertiaryButtonContainer = styled(Flex)`
  margin-top: ${({ theme }) => theme.spacing("-7")};
`;

export const useCarouselStepComponent = (stepComponents: React.FC[]) => {
  const getStepNumber = (step) => (step ? +step : 1);
  const getStepString = (step) => (step ? `${step}` : "1");
  const { getQueryParam, setQueryParam } = useQueryParam("step");
  const stepNumber = getStepNumber(getQueryParam());
  const Component = stepComponents[stepNumber - 1];
  const totalSteps = stepComponents.length;
  const onNext = useCallback(
    (ev, nextStep) => {
      setQueryParam(getStepString(nextStep));
    },
    [setQueryParam]
  );
  const onPrev = useCallback(
    (ev, prevStep) => {
      setQueryParam(getStepString(prevStep));
    },
    [setQueryParam]
  );
  return {
    Component,
    stepNumber,
    totalSteps,
    onNext,
    onPrev,
  };
};

export const Pills = ({
  totalSteps,
  stepNumber,
  color,
  height = "4px",
  width = "18px",
  justify = "center",
}: {
  totalSteps: number;
  stepNumber: number;
  height?: string;
  width?: string;
  color?: string;
  justify?: FlexProps["justify"];
}) => {
  return (
    <Flex justify={justify}>
      {Array.from(Array(totalSteps).keys()).map((page) => (
        <Pill key={`page-pill-${page}`} height={height} width={width} active={stepNumber - 1 === page} color={color} />
      ))}
    </Flex>
  );
};

export const Carousel = ({
  children,
  stepNumber,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  totalSteps,
  custom,
  swipeHandlers,
  ...animationParams
}: PropsWithChildren<CarouselProps>) => {
  const { isMobile } = useDetectDeviceSize();
  return (
    <Container>
      <AnimatePresence custom={custom} mode="wait">
        <LayoutGroup>
          <CarouselContent key={stepNumber} custom={custom} {...animationParams} {...swipeHandlers}>
            {children}
          </CarouselContent>
        </LayoutGroup>
      </AnimatePresence>
      <Pills totalSteps={totalSteps} stepNumber={stepNumber} />
      <AnimatePresence>
        <LayoutGroup>
          <ButtonsContainer key={stepNumber} fullWidth={!secondaryAction || isMobile}>
            {secondaryAction ? (
              <ButtonContainer>
                <Button
                  variant="Secondary"
                  onClick={secondaryAction.onAction}
                  fullWidth
                  data-dd-action-name={secondaryAction.analyticsId}
                >
                  {secondaryAction.content}
                </Button>
              </ButtonContainer>
            ) : null}
            <ButtonContainer fullWidth={!secondaryAction}>
              <Button
                variant="Primary"
                onClick={primaryAction.onAction}
                fullWidth
                data-dd-action-name={primaryAction.analyticsId}
              >
                {primaryAction.content}
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
          {tertiaryAction ? (
            <TertiaryButtonContainer width="100%">
              <Button
                variant="Tertiary"
                onClick={tertiaryAction.onAction}
                fullWidth
                data-dd-action-name={tertiaryAction.analyticsId}
              >
                {tertiaryAction.content}
              </Button>
            </TertiaryButtonContainer>
          ) : null}
        </LayoutGroup>
      </AnimatePresence>
    </Container>
  );
};
