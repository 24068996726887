import { createContext, useContext } from "react";
import type { BaseSchemas } from "api-types";

const AnalyticsContext = createContext<Partial<BaseSchemas.AnalyticsAttributes>>({});
const AnalyticsProvider = AnalyticsContext.Provider;

function useAnalyticsContext() {
  return useContext(AnalyticsContext);
}

export { AnalyticsProvider, AnalyticsContext, useAnalyticsContext };
