import { defineMessages } from "react-intl";

export const MFA_MESSAGES = defineMessages({
  mfaHeading: {
    id: "MFA: Multi-factor authentication",
    defaultMessage: "Multi-factor authentication",
  },
  mfaDescription: {
    id: "MFA: Multi-factor authentication (MFA) keeps accounts secure by adding an extra layer of protection beyond a password.",
    defaultMessage:
      "Multi-factor authentication (MFA) keeps accounts secure by adding an extra layer of protection beyond a password.",
  },
  removeMfaMethodTitle: {
    id: "MFA: Remove MFA Method?",
    defaultMessage: "Remove MFA Method?",
  },
  removeMfaConfirmation: {
    id: "MFA: Are you sure you want to remove <b>{label}</b> as an MFA method?",
    defaultMessage: "Are you sure you want to remove <b>{label}</b> as an MFA method?",
  },
  mfaTokenIsExpired: {
    id: "MFA: Your MFA token has expired and you need to get a new one to view your configured methods. Click on the button below to refresh it.",
    defaultMessage:
      "Your MFA token has expired and you need to get a new one to view your configured methods. Click on the button below to refresh it.",
  },
  refreshMFAToken: {
    id: "MFA: Refresh MFA Token",
    defaultMessage: "Refresh MFA Token",
  },
  enabled: {
    id: "MFA: Enabled",
    defaultMessage: "Enabled",
  },
  add: {
    id: "MFA: Add",
    defaultMessage: "Add",
  },
  remove: {
    id: "MFA: Remove",
    defaultMessage: "Remove",
  },
  cancel: {
    id: "MFA: Cancel",
    defaultMessage: "Cancel",
  },
  otpHelperText: {
    id: "MFA: We recommend Google Authenticator.",
    defaultMessage: "We recommend Google Authenticator.",
  },
  smsHelperText: {
    id: "MFA: Verifying your phone number is an easy way to provide another method.",
    defaultMessage: "Verifying your phone number is an easy way to provide another method.",
  },
});
