import { addError } from "@wayflyer/blackbox-fe";
import Cookies from "js-cookie";

import { brandedGetText } from "./i18n";

/**
 *  @deprecated Use `useAxios` from `@wayflyer/auth` instead
 */
export function executeFetch(
  method: string,
  url: RequestInfo,
  requestContentType: "multipart/form-data" | "application/json",
  requestData: any
) {
  const headers = {
    Accept: "application/json",
  };
  if (document.querySelector("[name=csrfmiddlewaretoken]")) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    headers["X-CSRFToken"] = document.querySelector("[name=csrfmiddlewaretoken]")!.getAttribute("value");
  }
  if (requestContentType === "application/json") {
    headers["Content-type"] = requestContentType;
  }

  const fetchInitProps: RequestInit = {
    cache: "no-cache",
    credentials: "include",
    headers: headers,
    method: method,
  };
  if (requestData != null) {
    if (requestContentType === "application/json") {
      fetchInitProps.body = JSON.stringify(requestData);
    } else if (requestContentType === "multipart/form-data") {
      const body: FormData = new FormData();
      Object.keys(requestData).map((fieldName) => body.append(fieldName, requestData[fieldName]));
      fetchInitProps.body = body;
    }
  }

  return fetch(url, fetchInitProps);
}

function isNetworkError(e) {
  if (
    e instanceof TypeError &&
    [
      "The network connection was lost.", // Safari
      "Failed to fetch", // Chrome
      "NetworkError when attempting to fetch resource.", // Firefox
      "Network request failed", // polyfill from https://github.com/github/fetch
    ].includes(e.message)
  ) {
    return true;
  }
  return false;
}

/**
 *  @deprecated Use `useAxios` from `@wayflyer/auth` instead
 */
export function handleFetchException(e, onFailure: (failureMessage: string) => any) {
  if (isNetworkError(e)) {
    onFailure(brandedGetText("fetch network error"));
  } else {
    addError(e);
    onFailure(brandedGetText("internal server error"));
  }
}

/**
 *  @deprecated Use `useAxios` from `@wayflyer/auth` instead
 */
function fetchAndHandle(
  method: string,
  url: RequestInfo,
  requestContentType: "multipart/form-data" | "application/json",
  requestData: any,
  onSuccess: (responseData: any) => any,
  onFailure: (failureMessage: string) => any
) {
  executeFetch(method, url, requestContentType, requestData)
    .then((response) => {
      if (!response.ok && response.status !== 400) {
        throw Error(`Fetch failed with status ${response.status}`);
      }
      return Promise.all([Promise.resolve(response), response.json()]);
    })
    .then(([response, json]) => {
      if (response.ok) {
        onSuccess(json);
      } else if (json && json.message) {
        // when the server responds with a 400 status and includes a JSON response with a "message", that's the error will display to the user.
        onFailure(json.message);
        if (json.errors) {
          addError(json.errors);
        }
      } else {
        throw Error(`Fetch failed with status ${response.status}`);
      }
    })
    .catch((e) => {
      if (e.message.includes("403")) {
        const cookies = document.cookie.split(";").map((v) => v.trim());
        const csrfTokens = cookies.filter((cookie) => cookie.includes("csrftoken"));
        if (csrfTokens.length > 1) {
          addError(new Error("403 Hit, more than one CSRF tokens found!"));
          Cookies.remove("csrftoken");
          window.location.href = "/";
        }
      }
      handleFetchException(e, onFailure);
    });
}

/**
 *  @deprecated Use `useAxios` from `@wayflyer/auth` instead
 */
export function post(
  url: RequestInfo,
  requestData: any,
  onSuccess: (responseData: any) => any,
  onFailure: (failureMessage: string) => any,
  requestContentType?: "multipart/form-data" | "application/json"
) {
  fetchAndHandle(
    "POST",
    url,
    requestContentType != null ? requestContentType : "application/json",
    requestData,
    onSuccess,
    onFailure
  );
}

/**
 *  @deprecated Use `useAxios` from `@wayflyer/auth` instead
 */
export function get(
  url: RequestInfo,
  onSuccess: (responseData: any) => any,
  onFailure: (failureMessage: string) => any
) {
  fetchAndHandle("GET", url, "application/json", null, onSuccess, onFailure);
}

/**
 *  @deprecated Use `useAxios` from `@wayflyer/auth` instead
 */
export function request(
  url: RequestInfo,
  method: string,
  requestData?: any,
  requestContentType?: "multipart/form-data" | "application/json"
): Promise<any> {
  return new Promise((resolve, reject) => {
    fetchAndHandle(
      method,
      url,
      requestContentType != null ? requestContentType : "application/json",
      requestData,
      (responseData) => resolve(responseData),
      (failureMessage) => reject(failureMessage)
    );
  });
}

/**
 *  @deprecated Use `useAxios` from `@wayflyer/auth` instead
 */
export function requestPost(
  url: RequestInfo,
  requestData: any,
  requestContentType?: "multipart/form-data" | "application/json"
): Promise<any> {
  return new Promise((resolve, reject) => {
    post(
      url,
      requestData,
      (responseData) => resolve(responseData),
      (failureMessage) => reject(failureMessage),
      requestContentType
    );
  });
}
