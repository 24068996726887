export const baseConfig = {
  local: {
    applicationId: "c7065ccc-4ada-4f13-8bf0-102a9ec71489",
    clientToken: "pub30db66489f71e1af87499f5108ce00fc",
    site: "datadoghq.eu",
    service: "wayflyer-customer-web-(local)",
    env: "local",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackFrustrations: false,
    traceSampleRate: 0,
    allowedTracingOrigins: ["https://app.wayflyerdev.com"],
  },
  prod: {
    applicationId: "c2f5ed75-ca0f-4194-884f-2cb59bb039ee",
    clientToken: "pubd4045714a73d50e4cc7bbecfc75e8e1a",
    site: "datadoghq.eu",
    env: "prod",
    service: "wayflyer-customer-web",
    enableExperimentalFeatures: ["clickmap"],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
    trackUserInteractions: true,
    trackFrustrations: true,
    trackSessionsAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    allowedTracingUrls: [
      "https://app.wayflyer.com",
      "https://app.wayflyer.team",
      "https://wayflyer.com",
      "https://www.wayflyer.com",
    ],
  },
};
