import { FormattedMessage, type IntlShape } from "react-intl";
import { Text, Link, type ErrorComponentProps } from "@wayflyer/flyui";
import { handleContactTeam } from "shared-utils";

export type ErrorVariant = "403" | "404" | "500" | "bad-link" | "submit-error" | "unknown";

export type ErrorVariantMap = Record<ErrorVariant, ErrorComponentProps>;

export const getCustomerErrorPresetMap = ({ intl }: { intl: IntlShape }): ErrorVariantMap => {
  const defaultPrimaryAction = {
    content: intl.formatMessage({ id: "Wayflyer home", defaultMessage: "Wayflyer home" }),
    onAction: () => window.location.replace(window.location.origin),
  };
  const defaultSecondaryAction = {
    content: intl.formatMessage({ id: "Go back", defaultMessage: "Go back" }),
    onAction: () => window.history.back(),
  };
  const errorVariants: ErrorVariantMap = {
    "403": {
      title: intl.formatMessage({ id: "error 403 default title", defaultMessage: "Access Denied" }),
      message: (
        <FormattedMessage
          id="error 403 default message"
          defaultMessage="<p>You don’t have permission to access this page. If you need access please <a>contact our support team</a>.</p>"
          values={{
            p: (chunks) => <Text data-testid="error-message-403">{chunks}</Text>,
            a: (chunks) => <Link onClick={handleContactTeam}>{chunks}</Link>,
          }}
        />
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
    "404": {
      title: intl.formatMessage({
        id: "error 404 default title",
        defaultMessage: "The page you’re looking for cannot be found",
      }),
      message: (
        <FormattedMessage
          id="error 404 default message"
          defaultMessage="<p>Sorry we can’t find the page you’re looking for. This could be because the web address is incorrect or no longer exists. For more information <a>contact our support team</a>.</p>"
          values={{
            p: (chunks) => <Text data-testid="error-message-404">{chunks}</Text>,
            a: (chunks) => <Link onClick={handleContactTeam}>{chunks}</Link>,
          }}
        />
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
    "500": {
      title: intl.formatMessage({
        id: "error 500 default title",
        defaultMessage: "Sorry, we’ve encountered an error",
      }),
      message: (
        <FormattedMessage
          id="error 500 default message"
          defaultMessage="<p>We are currently experiencing an internal server error. We’re working hard to get this fixed. Please try again later. For more information <a>contact our support team</a>.</p>"
          values={{
            p: (chunks) => <Text data-testid="error-message-500">{chunks}</Text>,
            a: (chunks) => <Link onClick={handleContactTeam}>{chunks}</Link>,
          }}
        />
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
    "bad-link": {
      title: intl.formatMessage({
        id: "error bad link default title",
        defaultMessage: "That link isn’t working",
      }),
      message: (
        <FormattedMessage
          id="error bad link default message"
          defaultMessage="<p>It might be invalid or expired. You can double check the link to make sure you entered it correctly, or <a>contact support</a> for help.</p>"
          values={{
            p: (chunks) => <Text data-testid="error-message-bad-link">{chunks}</Text>,
            a: (chunks) => <Link onClick={handleContactTeam}>{chunks}</Link>,
          }}
        />
      ),
      primaryAction: defaultPrimaryAction,
    },
    "submit-error": {
      title: intl.formatMessage({
        id: "error submit default title",
        defaultMessage: "Sorry, there was an error submitting your request",
      }),
      message: (
        <FormattedMessage
          id="error unknown default message"
          defaultMessage="<p>We’re working hard to get this fixed. Please go back or try refreshing the page. For more information <a>contact our support team</a>.</p>"
          values={{
            p: (chunks) => <Text data-testid="error-message-submit-error">{chunks}</Text>,
            a: (chunks) => <Link onClick={handleContactTeam}>{chunks}</Link>,
          }}
        />
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
    unknown: {
      title: intl.formatMessage({
        id: "error unknown default title",
        defaultMessage: "Sorry, we’ve encountered an error",
      }),
      message: (
        <FormattedMessage
          id="error unknown default message"
          defaultMessage="<p>We’re working hard to get this fixed. Please go back or try refreshing the page. For more information <a>contact our support team</a>.</p>"
          values={{
            p: (chunks) => <Text data-testid="error-message-unknown">{chunks}</Text>,
            a: (chunks) => <Link onClick={handleContactTeam}>{chunks}</Link>,
          }}
        />
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
  };

  return errorVariants;
};

export const getStaffErrorPresetMap = (): ErrorVariantMap => {
  const defaultPrimaryAction = {
    content: "Wayflyer home",
    onAction: () => window.location.replace(window.location.origin),
  };
  const defaultSecondaryAction = {
    content: "Go back",
    onAction: () => window.history.back(),
  };
  const errorVariants: ErrorVariantMap = {
    "403": {
      title: "Access Denied",
      message: (
        <Text>You don’t have permission to access this page. If you need access please contact our support team.</Text>
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
    "404": {
      title: "The page you’re looking for cannot be found",
      message: (
        <Text>
          Sorry we can’t find the page you’re looking for. This could be because the web address is incorrect or no
          longer exists. For more information contact our support team.
        </Text>
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
    "500": {
      title: "Sorry, we’ve encountered an error",
      message: (
        <Text>
          We are currently experiencing an internal server error. We’re working hard to get this fixed. Please try again
          later. For more information contact our support team.
        </Text>
      ),
      primaryAction: defaultPrimaryAction,
    },
    "bad-link": {
      title: "That link isn’t working",
      message: (
        <Text>
          It might be invalid or expired. You can double check the link to make sure you entered it correctly, or
          contact our support team.
        </Text>
      ),
      primaryAction: defaultPrimaryAction,
    },
    "submit-error": {
      title: "Sorry, there was an error submitting your request",
      message: (
        <Text>
          We’re working hard to get this fixed. Please go back or try refreshing the page. For more information contact
          our support team.
        </Text>
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
    unknown: {
      title: "Sorry, we’ve encountered an error",
      message: (
        <Text>
          We’re working hard to get this fixed. Please go back or try refreshing the page. For more information contact
          our support team
        </Text>
      ),
      primaryAction: defaultPrimaryAction,
      secondaryAction: defaultSecondaryAction,
    },
  };

  return errorVariants;
};
