const nonCustomerPaths = ["/", "/login", "/signup", "/internal"];

export function getPathParts(path: string, from = 0): string[] {
  return path.split("/").filter(Boolean).slice(from);
}

export function isCustomerPath(path: string): boolean {
  return getPathParts(path).length > 1 && !nonCustomerPaths.includes(path);
}

export function pathWithoutCustomerSlug(path: string): string {
  return `/${getPathParts(path, isCustomerPath(path) ? 1 : 0).join("/")}`;
}
