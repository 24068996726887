import { axiosClient } from "@wayflyer/auth-sdk";
import type { FundingApplicationSchemas } from "api-types";

export const getAIRsDataQueryOptions = (customerDomain: string) => {
  const url = `/${customerDomain}/funding/api/review/air/data`;

  return {
    queryKey: [url],
    queryFn: () => axiosClient.get<FundingApplicationSchemas.AdditionalInformationRequestsData>(url),
    select: ({ data }): FundingApplicationSchemas.AdditionalInformationRequestsData => data,
    refetchInterval: 30000,
  };
};
