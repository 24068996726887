import * as React from "react";
import { type IntlShape, createIntl, createIntlCache } from "react-intl";
import { addError } from "@wayflyer/blackbox-fe";

import { requestPost } from "../request";

import { useMultiFileUploadReducer, type BaseUploadedFile } from "./multi-file-upload-reducer";

import { uploadDocument } from ".";

const stubIntl = createIntl(
  {
    locale: "en",
    messages: {},
  },
  createIntlCache()
);

const noOp: () => void = () => {};

const RejectCodeToErrorCode = {
  "file-too-large": "FileSizeException",
  "file-invalid-type": "FileTypeNotAllowedException",
};

const FileUploadErrorCodes = {
  FileTypeNotAllowedException: ({ intl, supportedFileExtensions }) =>
    intl.formatMessage(
      {
        id: "upload manager: Invalid file type",
        defaultMessage: "Invalid file type. Supported files: {supportedFileExtensions}",
      },
      { supportedFileExtensions: supportedFileExtensions.join(", ") }
    ),
  FileSizeException: ({ intl, sizeLimitMB }) =>
    intl.formatMessage(
      { id: "upload manager: File size limit exceeded", defaultMessage: "File size exceeds limit of {sizeLimitMB}" },
      { sizeLimitMB: `${sizeLimitMB}MB` }
    ),
};

type UploadManagerOptions<TUploadedFile> = {
  createUrlPath: string;
  confirmUploadPath: string;
  deleteUploadPath: string;
  downloadUploadPath?: string;
  supportedFileExtensions?: string[];
  sizeLimitMB?: number;
  onUploadsComplete?: () => void;
  onRemoveComplete?: () => void;
  onUploadSuccess?: () => void;
  onUploadFailed?: () => void;
  onRemoveFailed?: () => void;
  files: TUploadedFile[] | Record<string, TUploadedFile[]>;
};

export function uploadManagerHookFactory<TUploadedFile extends BaseUploadedFile>(
  {
    files,
    createUrlPath,
    confirmUploadPath,
    deleteUploadPath,
    downloadUploadPath,
    supportedFileExtensions,
    sizeLimitMB,
    onUploadsComplete = noOp,
    onRemoveComplete = noOp,
    onUploadSuccess = noOp,
    onUploadFailed = noOp,
    onRemoveFailed = noOp,
  }: UploadManagerOptions<TUploadedFile>,
  intl: IntlShape = stubIntl
) {
  return function useDocumentUpload(id?: number) {
    const [uploadsState, dispatch] = useMultiFileUploadReducer<TUploadedFile>(id ? files[id] : files);

    React.useEffect(() => {
      if (uploadsState.uploadsComplete) {
        onUploadsComplete();
      }
    }, [uploadsState.uploadsComplete]);

    const uploadFile = React.useCallback(
      (file: File): Promise<TUploadedFile> =>
        uploadDocument(file, {
          createUrlPath,
          confirmUploadPath,
          id,
        }),
      [id]
    );

    const startFileUpload = React.useCallback(
      async (file) => {
        if (file) {
          dispatch({ type: "uploadStarted", payload: { file } });
          let uploadedFile;
          try {
            uploadedFile = await uploadFile(file);
            onUploadSuccess();
            dispatch({ type: "uploadFinished", payload: { file, uploadedFile: uploadedFile as TUploadedFile } });
          } catch (error) {
            const messageDetail = FileUploadErrorCodes[error]
              ? FileUploadErrorCodes[error]({ intl, supportedFileExtensions, sizeLimitMB })
              : intl.formatMessage({
                  id: "upload manager: on failure",
                  defaultMessage: "Upload failed - please contact support if the problem persists",
                });
            onUploadFailed();
            dispatch({ type: "uploadFailed", payload: { file, error: messageDetail } });
            addError(error);
          }
        }
      },
      [dispatch, uploadFile]
    );

    const onFilesAdded = React.useCallback(
      (files, rejectedFiles) => {
        dispatch({ type: "filesAdded", payload: { files } });
        for (const file of files) {
          startFileUpload(file);
        }

        const hasValidationErrors = !!rejectedFiles.length;

        if (hasValidationErrors) {
          dispatch({ type: "filesAdded", payload: { files: rejectedFiles.map(({ file }) => file) } });

          for (const { file, errors } of rejectedFiles) {
            // files rejected by dropzone, user error so supress log
            const errorCode = RejectCodeToErrorCode[errors[0].code]
              ? RejectCodeToErrorCode[errors[0].code]
              : errors[0].code;
            const messageDetail = FileUploadErrorCodes[errorCode]
              ? FileUploadErrorCodes[errorCode]({ intl, supportedFileExtensions, sizeLimitMB })
              : intl.formatMessage({
                  id: "upload manager: on failure",
                  defaultMessage: "Upload failed - please contact support if the problem persists",
                });

            dispatch({ type: "uploadFailed", payload: { file, error: messageDetail } });
          }

          onUploadFailed();
        }
      },
      [dispatch, startFileUpload]
    );

    const onFileRemoved = React.useCallback(
      async (file, uploadedFile) => {
        if (uploadedFile) {
          try {
            await requestPost(deleteUploadPath, { key: uploadedFile.objectKey, id });

            dispatch({ type: "fileRemoved", payload: { file } });
            onRemoveComplete();
          } catch (error) {
            addError(error);

            const errorDetails = intl.formatMessage({
              id: "upload manager: on remove failure",
              defaultMessage: "File removal failed - please contact support if the problem persists",
            });

            dispatch({ type: "removeFailed", payload: { file, error: errorDetails } });
            onRemoveFailed();
          }
        } else {
          dispatch({ type: "fileRemoved", payload: { file } });
        }
      },
      [dispatch, id]
    );

    const onFileDownload = React.useCallback(async (params) => {
      if (params && downloadUploadPath) {
        const { url } = await requestPost(downloadUploadPath, params);
        window.open(url, "_blank");
      }
    }, []);

    return {
      uploadsState,
      onFilesAdded,
      onFileDownload,
      onFileRemoved,
      supportedFileExtensions,
      sizeLimitMB,
    };
  };
}
