import { addError } from "@wayflyer/blackbox-fe";

declare const window: any;

export function showMessageComposer() {
  window.Intercom("show");
}

export function hideMessageComposer() {
  window.Intercom("hide");
}

export const handleContactTeam = () => {
  try {
    showMessageComposer();
  } catch (e) {
    addError("Intercom chat not opening >>", e);
    // Fall back to email
    window.open("mailto:support@wayflyer.com", "_blank");
  }
};
