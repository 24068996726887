// This is the SUPPORTED_CURRENCIES list from wf.utils.currencies, but in alphabetical order

import type { Item } from "@wayflyer/flyui";

// c.f. https://github.com/wayflyer/utils/blob/main/wf/utils/currencies.py
export enum Currencies {
  AUD = "AUD",
  CAD = "CAD",
  CHF = "CHF",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  HUF = "HUF",
  JPY = "JPY",
  MXN = "MXN",
  NOK = "NOK",
  NZD = "NZD",
  PLN = "PLN",
  RUB = "RUB",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
}

export const MCA_CURRENCY_OPTIONS = [
  { label: "EUR", value: "EUR" },
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "AUD", value: "AUD" },
  { label: "NZD", value: "NZD" },
  { label: "CAD", value: "CAD" },
  { label: "DKK", value: "DKK" },
  { label: "SEK", value: "SEK" },
];

export const currencyOptions: Item[] = MCA_CURRENCY_OPTIONS.map((currency) => {
  return { id: currency.value, text: currency.label };
});

export const isEuroCountry = (countryCode: string) => {
  return ["BE", "DE", "IE", "ES", "NL"].includes(countryCode);
};

export const COUNTRY_CURRENCY_MAP: Record<string, string> = {
  AU: "AUD",
  CA: "CAD",
  DK: "DKK",
  EU: "EUR",
  GB: "GBP",
  SE: "SEK",
  US: "USD",
};
