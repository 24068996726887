import { Flex, PageTitle, Text, TextContainer, useMediaQuery } from "@wayflyer/flyui";
import { styled } from "styled-components";

export interface HeaderProps {
  heading: string;
  informationText: string | React.ReactNode;
  image?: React.ReactNode;
  imageAlign?: "right" | "top";
}

const ImageContainer = styled.div`
  img {
    max-height: 150px;
    max-width: 150px;
  }
`;

const HeroImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Header = ({ heading, informationText, image, imageAlign = "right" }: HeaderProps) => {
  const query = useMediaQuery();
  const isGreaterThanOrEqualTablet = query("md").windowGt;
  return imageAlign === "top" ? (
    <Flex align="center" justify="space-between" direction="column" gap="6">
      {image ? <HeroImageContainer>{image}</HeroImageContainer> : null}
      <Flex align="center" direction="column" gap="4">
        <PageTitle>{heading}</PageTitle>
        <div>{typeof informationText === "string" ? <Text>{informationText}</Text> : informationText}</div>
      </Flex>
    </Flex>
  ) : (
    <Flex wrap="nowrap" justify="space-between" gap="8">
      <TextContainer>
        <PageTitle>{heading}</PageTitle>
        <div>{typeof informationText === "string" ? <Text>{informationText}</Text> : informationText}</div>
      </TextContainer>
      {isGreaterThanOrEqualTablet && image ? <ImageContainer>{image}</ImageContainer> : null}
    </Flex>
  );
};
