import { usePostHog, PostHogProvider } from "posthog-js/react";
import { useLocation } from "react-router";
import { useEffect, type ComponentProps } from "react";
// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";
import Cookies from "js-cookie";

const PROD = import.meta.env.PROD;

const POSTHOG_KEY = "phc_5Ye2r14imIkan5Ea4FURNXcLdrUt6Ztf6ouzNppU5cn";
export const POSTHOG_DEV_KEY = "phc_1SUEXzRAezj8infuQ9i1DGZdpmrY8uPvXWPeoXjpJDx";
const POSTHOG_HOST = "https://ph.wayflyer.com";

const env = PROD && !window.origin.includes("dev") ? "prod" : "local";

const searchParams = new URLSearchParams(window.location.href);
const distinctID = searchParams.get("distinct_id") ?? undefined;
const sessionID = searchParams.get("session_id") ?? undefined;

posthog.init(env === "prod" ? POSTHOG_KEY : POSTHOG_DEV_KEY, {
  api_host: POSTHOG_HOST,
  person_profiles: "identified_only",
  capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  capture_pageleave: true,
  autocapture: {
    element_allowlist: ["a", "button"],
  },
  bootstrap: {
    distinctID,
    sessionID,
  },
  ui_host: "https://eu.i.posthog.com",
  persistence: getPersistence(),
});

/**
 * If a OneTrust cookie is present with performance tracking consent, then we
 * can use the default localStorage+cookie persistence. Otherwise, we use
 * memory persistence.
 */
export function getPersistence(): "localStorage+cookie" | "memory" {
  const consentRaw = Cookies.get("OptanonConsent");
  if (!consentRaw) {
    return "memory";
  }

  // OneTrust Cookies: https://my.onetrust.com/s/article/UUID-2dc719a8-4be5-8d16-1dc8-c7b4147b88e0
  const consentData = consentRaw.split("&").reduce((acc: Record<string, string>, kv) => {
    const [k, v] = kv.split("=");
    acc[decodeURIComponent(k)] = decodeURIComponent(v);
    return acc;
  }, {});

  // Cookie Group IDs: https://my.onetrust.com/s/article/UUID-8102e851-d860-d465-d8d6-b1d636d68eb9
  // C0002 is the ID for the "Performance" group, and 1 means they've consented.
  if (consentData["groups"]?.includes("C0002:1")) {
    return "localStorage+cookie";
  } else {
    return "memory";
  }
}

export function PHProvider({ children }: Omit<ComponentProps<typeof PostHogProvider>, "client">) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

export function usePostHogPageView() {
  const location = useLocation();
  const posthog = usePostHog();
  useEffect(() => {
    if (posthog) {
      posthog.capture("$pageview");
    }
  }, [location, posthog]);
}
