import { Text, Link, type SupportingContentProps } from "@wayflyer/flyui";
import { useIntl } from "react-intl";
import { styled } from "styled-components";

import { fundingMessages } from "../../messages";
import { FundingRequestRoutes, matchRoute } from "../../funds-requests";

export const IntercomLink = styled.span`
  color: ${({ theme }) => theme.palette.utility.focus};
  cursor: pointer;
`;

export const useFundingSupportingLinks = ({
  pathname,
}: {
  pathname: string;
}): SupportingContentProps["supportingLinks"] => {
  const currentRoute = matchRoute(pathname);
  const intl = useIntl();
  const messages = fundingMessages(intl);

  switch (currentRoute) {
    case FundingRequestRoutes.KYC:
      return [
        {
          heading: messages.shared.helpTitle,
          links: [
            {
              linkText: messages.kyc.learnMoreAboutVerifyingMyBusiness,
              url: "https://www.wayflyer.com/help-center/business-verification",
            },
          ],
        },
      ];

    case FundingRequestRoutes.CONTRACTS_IN_PROGRESS:
    case FundingRequestRoutes.CONTRACTS_COMPLETED:
      return [
        {
          heading: messages.shared.helpTitle,
          links: [
            {
              linkText: messages.contracts.learnMoreAboutFeesTermsAndPayments,
              url: "https://www.wayflyer.com/help-center/funding",
            },
          ],
        },
      ];

    case FundingRequestRoutes.OFFERS:
    case FundingRequestRoutes.EXPIRED:
      return [
        {
          heading: messages.shared.helpTitle,
          links: [
            {
              linkText: messages.shared.learnMoreAboutApplyingForFunding,
              url: "https://www.wayflyer.com/help-center/applying-for-funding",
            },
          ],
        },
        {
          heading: intl.formatMessage(messages.offers.ctaTitle),
          links: [
            {
              linkText: (
                <Text>
                  {intl.formatMessage(messages.offers.ctaSubtext, {
                    span: (text: string) => <IntercomLink className="intercom_offers_workflow">{text}</IntercomLink>,
                    link: (text: string) => <Link url="mailto:support@wayflyer.com">{text}</Link>,
                  })}
                </Text>
              ),
            },
          ],
        },
      ];

    default:
      return [
        {
          heading: messages.shared.helpTitle,
          links: [
            {
              linkText: messages.shared.learnMoreAboutApplyingForFunding,
              url: "https://www.wayflyer.com/help-center/applying-for-funding",
            },
          ],
        },
      ];
  }
};
