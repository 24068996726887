import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const MAX_DATE_NO_CSV = 12;

export const getEndDate = (endDateStr?: string | null): dayjs.Dayjs => {
  return endDateStr ? dayjs(endDateStr) : dayjs();
};

export const isEndDateCurrentMonth = (endDateStr?: string | null): boolean => {
  const endDate = getEndDate(endDateStr);
  // if we are given endDateStr from BE it is in utc so we need to compare to utc here
  const currentDate = endDateStr ? dayjs.utc() : dayjs();
  return endDate.format("YYYY-MM") === currentDate.format("YYYY-MM");
};

export const getEndDateForPeriodText = (endDateStr?: string | null): dayjs.Dayjs => {
  const endDate = getEndDate(endDateStr);
  if (isEndDateCurrentMonth(endDateStr) && endDate.date() <= MAX_DATE_NO_CSV) {
    // return the last day of previous month as we are only looking for PDFs for full months now
    return endDate.subtract(1, "month").endOf("month");
  }
  return endDate;
};
