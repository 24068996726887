import type { IntlShape } from "react-intl";

export const fundingMessages = (intl: IntlShape) => ({
  shared: {
    learnMore: intl.formatMessage({ id: "learn more", defaultMessage: "Learn more" }),
    helpTitle: intl.formatMessage({ id: "help title", defaultMessage: "Need help?" }),
    learnMoreAboutApplyingForFunding: intl.formatMessage({
      id: "Learn more about applying for funding",
      defaultMessage: "Learn more about applying for funding",
    }),
  },
  offers: {
    ctaTitle: { id: "offers v2 cta: support heading", defaultMessage: "Want to chat to us?" },
    ctaSubtext: {
      id: "offers v2 cta: support text",
      defaultMessage:
        "You can reach us on our <span>live chat</span> or email us at <link>support@wayflyer.com</link>.",
    },
  },
  kyc: {
    learnMoreAboutVerifyingMyBusiness: intl.formatMessage({
      id: "Learn more about verifying my business",
      defaultMessage: "Learn more about verifying my business",
    }),
  },
  contracts: {
    learnMoreAboutFeesTermsAndPayments: intl.formatMessage({
      id: "Learn more about fees, terms and payments",
      defaultMessage: "Learn more about fees, terms and payments",
    }),
    orderFormTitle: intl.formatMessage({
      id: "funding help link order form title",
      defaultMessage: "Order Form",
    }),
    contractAlreadyAcceptedMessage: intl.formatMessage({
      id: "contract already accepted message",
      defaultMessage: "You have already accepted this contract",
    }),
    bankContractAcceptedMessage: intl.formatMessage({
      id: "bank details contract accepted",
      defaultMessage: "Contract already accepted - these details are for your reference only.",
    }),
    bankDetailsNeededMessage: intl.formatMessage({
      id: "bank details needed banner message",
      defaultMessage:
        "We don’t have your most recent bank account information to file. Please provide us with your bank details so we know where to send your funds.",
    }),
    disclosureNeededMessage: intl.formatMessage({
      id: "disclosure needed message",
      defaultMessage: "You must review the disclosure before accepting funding.",
    }),
    applyingForFinance: intl.formatMessage({
      id: "applying for finance label",
      defaultMessage: "Applying for finance",
    }),
    understandingFeesAndTerms: intl.formatMessage({
      id: "understanding fees and terms label",
      defaultMessage: "Understanding fees and terms",
    }),
    addBankInformationButton: intl.formatMessage({
      id: "add bank details cta button",
      defaultMessage: "Add bank account information",
    }),
  },
  fundingApplication: {
    intendedUseMarketing: intl.formatMessage({
      id: "intended use option marketing",
      defaultMessage: "Marketing",
    }),
    intendedUseInventory: intl.formatMessage({
      id: "intended use option inventory",
      defaultMessage: "Inventory purchasing",
    }),
    intendedUseImproveCashflow: intl.formatMessage({
      id: "intended use option improving cash flow",
      defaultMessage: "Improving cash flow",
    }),
    intendedUseResearchDevelopment: intl.formatMessage({
      id: "intended use option research development",
      defaultMessage: "Research and development",
    }),
    intendedUseBusinessExpansion: intl.formatMessage({
      id: "intended use option business expansion",
      defaultMessage: "Company expansion",
    }),
    intendedUseOther: intl.formatMessage({
      id: "intended use option other",
      defaultMessage: "Other",
    }),
  },
});
