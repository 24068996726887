import { AnalyticsEventId, type AnalyticsEventPayload } from "@wayflyer/flyui";
import { addError } from "@wayflyer/blackbox-fe";

import { pushEvent } from "./wf-analytics";

const defaultEventTimeout = 1500;

/**
 *
 * helper function to push an event into the datalayer
 * and try to wait until we get confirmation that the event was received
 * by google tag manager or timeout after a given period of time
 *
 * Useful at where we want to track an event but also trigger a new page load or redirect
 * example: login, signup, finished bank connect
 * we want to track these events before loading a new page (which would cancel the event)
 * but we have the timeout so that we don't create a poor user experience
 */
function pushEventOrTimeout(context: any = {}, name: string, attrs: any = {}) {
  return Promise.race([
    new Promise((resolve, reject) =>
      setTimeout(() => reject(`timed out when attempting to track analytics event`), defaultEventTimeout)
    ),
    new Promise<void>((resolve) => {
      if (!window.google_tag_manager) {
        return resolve();
      }
      pushEvent(context, name, { ...attrs, eventCallback: resolve });
    }),
  ]).catch((e) => {
    addError(e);
  });
}

function pageView(context?: any) {
  pushEvent(context, "wfe_pageview");
}

function loginSuccess(context?: any) {
  return pushEventOrTimeout(context, "wfe_login", { result: "success" });
}

function loginFailed(context?: any) {
  pushEvent(context, "wfe_login", { result: "fail" });
}

function signUpSuccess(context?: any) {
  return pushEventOrTimeout(context, "wfe_registration").then(() => {
    return loginSuccess(context);
  });
}

function onboardingFundsRequest(skipped: boolean, context?: any) {
  pushEvent(context, "wfe_onboarding_funds_request", { skipped });
}

function bankStatementsConnectBegin(context: any = {}, type = "plaid") {
  pushEvent(context, "wfe_bank_statements_connect_begin", { type });
}

function bankStatementsReconnectBegin(context: any = {}, type = "plaid") {
  pushEvent(context, "wfe_bank_statements_reconnect_begin", { type });
}

function bankStatementsConnectClosed(context: any = {}, type = "plaid") {
  pushEvent(context, "wfe_bank_statements_connect_closed", { type });
}

function bankStatementsPlaidError(context: any = {}, metadata: any) {
  const { error_code, error_message, error_type } = metadata;
  pushEvent(context, "wfe_bank_statements_plaid_error", {
    errorCode: error_code,
    errorMessage: error_message,
    errorType: error_type,
  });
}

function bankStatementsAccountConnected(context: any = {}, type = "plaid") {
  pushEvent(context, "wfe_bank_statements_account_connected", { type });
}

function bankStatementsAccountReconnected(context: any = {}, type = "plaid") {
  pushEvent(context, "wfe_bank_statements_account_reconnected", { type });
}

function bankStatementsConnectAccountFailed(context: any = {}, type = "plaid") {
  pushEvent(context, "wfe_bank_statements_connect_account_failed", { type });
}

function bankStatementsAccountAlreadyConnected(context: any = {}, type = "plaid") {
  pushEvent(context, "wfe_bank_statements_account_already_connected", { type });
}

function bankStatementsConnectAbortedForEmail(context: any = {}) {
  pushEvent(context, "wfe_bank_statements_connect_aborted_for_email");
}

function bankStatementsEmailBegin(context: any = {}) {
  pushEvent(context, "wfe_bank_statements_email_begin");
}

function bankStatementsUploadBegin(context: any = {}) {
  pushEvent(context, "wfe_bank_statements_upload_begin");
}

function bankStatementsInitialSelectionSkipped(context: any = {}) {
  return pushEventOrTimeout(context, "wfe_bank_statements_skip");
}

function bankStatementsDone(context: any = {}, screen: string) {
  return pushEventOrTimeout(context, "wfe_bank_statements_done", { screen });
}

function openedHelpCentre(context: any = {}) {
  pushEvent(context, "wfe_helpcentre_opened");
}

function inviteMembersSendClicked(context: any = {}) {
  pushEvent(context, "wfe_invite_members_modal_send_pressed");
}

function inviteMembersModalClosed(context: any = {}, reason: string) {
  pushEvent(context, "wfe_invite_members_modal_closed", { reason });
}

function inviteMembersModalOpened(context: any = {}) {
  pushEvent(context, "wfe_invite_members_modal_opened");
}

const inlineError = (context: any = {}, attrs: any = {}) => {
  pushEvent(context, "wfa_inlineError", attrs);
};

const fireClickEvent = (context: any = {}, attrs: any = {}) => {
  pushEvent(context, "wfa_click", attrs);
};

export const flyUIAnalyticsHandler =
  (context: any = {}) =>
  (eventPayload: AnalyticsEventPayload) => {
    switch (eventPayload.id) {
      case AnalyticsEventId.modalClose:
        if (eventPayload.payload.type === "invite") {
          inviteMembersModalClosed(context, eventPayload.payload.reason);
        }
        pushEvent(context, "wfa_modal_close", { ...eventPayload.payload });
        break;
      case AnalyticsEventId.modalOpen:
        if (eventPayload.payload.type === "invite") {
          inviteMembersModalOpened(context);
        }
        pushEvent(context, "wfa_modal_open", { ...eventPayload.payload });
        break;
      case AnalyticsEventId.click:
        pushEvent(context, "wfa_click", { ...eventPayload.payload });
        break;
      case AnalyticsEventId.toastVisible:
        pushEvent(context, "wfa_toast", { ...eventPayload.payload });
        break;
      case AnalyticsEventId.inlineError:
        pushEvent(context, "wfa_inlineError", { ...eventPayload.payload });
        break;
    }
  };

// Data-sources
const onboardingStatesForCategories = {
  Analytics: "CONNECT_ANALYTICS_PLATFORM",
  Marketing: "CONNECT_MARKETING_PLATFORM",
  Shopping: "CONNECT_SHOPPING_PLATFORM",
  Other: "OTHER",
};

type DataSourceConnectionProps = {
  dataSourceSlug?: string;
  dataSourceCategory?: string;
  firstConnection?: boolean;
};

function validateDataSourceConnection(connectionDetails = {} as DataSourceConnectionProps) {
  const errors: string[] = [];
  if (!connectionDetails.dataSourceSlug) {
    errors.push("missing property 'datasourceSlug'");
  }
  if (!connectionDetails.dataSourceCategory) {
    errors.push("missing property 'dataSourceCategory'");
  }
  if (!connectionDetails.firstConnection) {
    errors.push("missing property 'firstConnection'");
  }
  return errors;
}

function updateOnboardingState(connectionDetails = {} as DataSourceConnectionProps, context?: any) {
  const validationErrors = validateDataSourceConnection(connectionDetails);
  if (validationErrors.length > 0) {
    addError(
      new Error(
        `analytics.updateOnboardingState called with invalid connection details:\n${validationErrors.join("\n")}`
      )
    );
  } else if (connectionDetails.firstConnection === true) {
    pushEvent(context, "wfe_onboarding_state", {
      state: onboardingStatesForCategories[connectionDetails.dataSourceCategory || "Other"],
      slug: connectionDetails.dataSourceSlug,
    });
  }
}
function requestedMagentoAccessTokenInstructions(context: any = {}) {
  pushEvent(context, "wfe_magento_instructions_link");
}

function requestedBigCommerceAccessTokenInstructions(context: any = {}) {
  pushEvent(context, "wfe_bigcommerce_instructions_link");
}

function requestedPrestaShopWebserviceKeyInstructions(context: any = {}) {
  pushEvent(context, "wfe_prestashop_instructions_link");
}

function requestedShopware5AccessTokenInstructions(context: any = {}) {
  pushEvent(context, "wfe_shopware5_instructions_link");
}

const analytics = {
  pageView,
  loginSuccess,
  loginFailed,
  signUpSuccess,
  onboardingFundsRequest,
  updateOnboardingState,
  requestedMagentoAccessTokenInstructions,
  requestedBigCommerceAccessTokenInstructions,
  requestedPrestaShopWebserviceKeyInstructions,
  requestedShopware5AccessTokenInstructions,
  bankStatementsPlaidError,
  bankStatementsConnectBegin,
  bankStatementsReconnectBegin,
  bankStatementsConnectClosed,
  bankStatementsAccountConnected,
  bankStatementsConnectAccountFailed,
  bankStatementsAccountAlreadyConnected,
  bankStatementsAccountReconnected,
  bankStatementsConnectAbortedForEmail,
  bankStatementsEmailBegin,
  bankStatementsUploadBegin,
  bankStatementsInitialSelectionSkipped,
  bankStatementsDone,
  openedHelpCentre,
  inviteMembersSendClicked,
  inlineError,
  fireClickEvent,
};

export default analytics;
