export enum FundingRequestRoutes {
  OFFERS = "/offers",
  KYC = "/kyc",
  ORDER_FORM = "/financing-contract-order-form",
  BANK_ACCOUNT_INFO = "/financing-contract-bank-details",
  CONTRACTS_IN_PROGRESS = "/contracts/review",
  CONTRACTS_COMPLETED = "/contracts/signed",
  EXPIRED = "/expired",
  DISQUALIFIED = "/disqualified",
  NOT_FOUND = "/404",
}

export const matchRoute = (route: string): FundingRequestRoutes => {
  for (const key in FundingRequestRoutes) {
    if (route.includes(FundingRequestRoutes[key])) {
      return FundingRequestRoutes[key];
    }
  }
  return FundingRequestRoutes.NOT_FOUND;
};
