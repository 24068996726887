import type { IntlShape } from "react-intl";
import { defineMessages } from "react-intl";

export const getNavItemNameFromId = (intl: IntlShape, id: string): string => {
  const msg = defineMessages({
    ["customer tasks page title"]: {
      id: "customer tasks page title",
      defaultMessage: "Tasks",
    },
    ["apply for funding title"]: {
      id: "apply for funding title",
      defaultMessage: "Apply for funding",
    },
    ["funds request title"]: {
      id: "funds request title",
      defaultMessage: "Application",
    },
    ["cards title"]: {
      id: "cards title",
      defaultMessage: "Card",
    },
    ["advances page title"]: {
      id: "advances page title",
      defaultMessage: "Advances",
    },
    ["statements page title"]: {
      id: "statements page title",
      defaultMessage: "Statements",
    },
    ["financing dashboard title"]: {
      id: "financing dashboard title",
      defaultMessage: "Overview",
    },
    ["financing page title"]: {
      id: "financing page title",
      defaultMessage: "Financing",
    },
    ["performance overview page title"]: {
      id: "performance overview page title",
      defaultMessage: "Overview",
    },
    ["performance ltv page title"]: {
      id: "performance ltv page title",
      defaultMessage: "LTV",
    },
    ["performance channels page title"]: {
      id: "performance channels page title",
      defaultMessage: "Channel",
    },
    ["performance products title"]: {
      id: "performance products title",
      defaultMessage: "Products",
    },
    ["performance googleads page title"]: {
      id: "performance googleads page title",
      defaultMessage: "Google Ads",
    },
    ["performance metaads page title"]: {
      id: "performance metaads page title",
      defaultMessage: "Meta Ads",
    },
    ["email report page title"]: {
      id: "email report page title",
      defaultMessage: "Report Settings",
    },
    ["performance paid channel summary page title"]: {
      id: "performance paid channel summary page title",
      defaultMessage: "Paid Channels",
    },
    ["performance page title"]: {
      id: "performance page title",
      defaultMessage: "Performance",
    },
    ["shared pixel root page title"]: {
      id: "shared pixel root page title",
      defaultMessage: "Engager",
    },
    ["shared pixel home page title"]: {
      id: "shared pixel home page title",
      defaultMessage: "Overview",
    },
    ["shared pixel meta settings page title"]: {
      id: "shared pixel meta settings page title",
      defaultMessage: "Shared Pixels",
    },
    ["shared pixel adset performance page title"]: {
      id: "shared pixel adset performance page title",
      defaultMessage: "Performance",
    },
    ["shared pixel onboarding page title"]: {
      id: "shared pixel onboarding page title",
      defaultMessage: "Get Started",
    },
    ["tiktok page title"]: {
      id: "tiktok page title",
      defaultMessage: "TikTok",
    },
    ["tiktok ad spend limit page title"]: {
      id: "tiktok ad spend limit page title",
      defaultMessage: "Spend Limit",
    },
    ["connections page title"]: {
      id: "connections page title",
      defaultMessage: "Connections",
    },
    ["benchmarks page title"]: {
      id: "benchmarks page title",
      defaultMessage: "Benchmarks",
    },
    ["banking page title"]: {
      id: "banking page title",
      defaultMessage: "Banking",
    },
    ["banking accounts page title"]: {
      id: "bank accounts page title",
      defaultMessage: "Accounts",
    },
    ["banking payments page title"]: {
      id: "bank payments page title",
      defaultMessage: "Payments",
    },
    ["banking recipients page title"]: {
      id: "bank recipients page title",
      defaultMessage: "Recipients",
    },
    ["banking cards page title"]: {
      id: "bank cards page title",
      defaultMessage: "Cards",
    },
    ["banking transactions page title"]: {
      id: "bank transactions page title",
      defaultMessage: "Transactions",
    },
    ["documents page title"]: {
      id: "documents page title",
      defaultMessage: "Documents",
    },
    ["banking check deposits page title"]: {
      id: "banking check deposits page title",
      defaultMessage: "Check Deposits",
    },
    ["financial_os page title"]: {
      id: "financial_os page title",
      defaultMessage: "Growth Planner",
    },
    ["financial_os home page title"]: {
      id: "financial_os home page title",
      defaultMessage: "Dashboard",
    },
    ["financial_os cashflow page title"]: {
      id: "financial_os cashflow page title",
      defaultMessage: "Cashflow",
    },
    ["financial_os scenario page title"]: {
      id: "financial_os scenario page title",
      defaultMessage: "Plans",
    },
    ["financial_os transactions page title"]: {
      id: "financial_os transactions page title",
      defaultMessage: "Transactions",
    },
    ["help centre title"]: {
      id: "help centre title",
      defaultMessage: "Help center",
    },
    ["internal ltv cac page title"]: {
      id: "internal ltv cac page title",
      defaultMessage: "LTV-CAC",
    },
    ["internal revenue page title"]: {
      id: "internal revenue page title",
      defaultMessage: "Revenue",
    },
    ["internal tiktok spend page title"]: {
      id: "internal tiktok spend page title",
      defaultMessage: "TikTok Ad Spend",
    },
    ["internal nav title"]: {
      id: "internal nav title",
      defaultMessage: "Internal",
    },
    ["customer referrals page title"]: {
      id: "customer referrals page title",
      defaultMessage: "Referrals",
    },
  })[id];

  return msg ? intl.formatMessage(msg) : id;
};
