import { findOwnerForRoute } from "@wayflyer/blackbox-fe";
import { useEffect, useState } from "react";

export type CodeOwner = "unknown" | "platform" | "mca" | "growthacq" | "product-services" | "npd";

export const displayOwners: Record<CodeOwner, string> = {
  platform: "S05D520U9NE", // @fefo-support
  mca: "S04CP8888AF", // // @mca-support
  "product-services": "S06FCBXMC1H", // @product-services-support
  growthacq: "S02MMF67T46", // @growthacq
  npd: "S06LD7PNWUC", // @npd-support
  unknown: "S05D520U9NE", // @fefo-support
};

/**
 * Wildcarding routes requires the use of a named parameter followed by
 * an asterisk,
 *
 * e.g.: `:wildcard*`
 *
 * Wildcards must be the last parameter in the route pattern.
 *
 * e.g.: "/:slug/financing/:wildcard*": "mca",
 *
 * A more specific route pattern will take precedence over a wildcard route pattern.\
 * You cannot use a wildcard after a hash (#) in a route pattern.
 *
 * we've chosen `wildcard` as our convention for the name of the wildcard parameter.
 */

export const viewPathsOwners: Record<string, CodeOwner> = {
  "/403": "platform",
  "/404": "platform",
  "/500": "platform",
  "/login": "platform",
  "/logout": "platform",
  "/staff/commands": "platform",
  "/staff/deals": "mca",
  "/staff/contracts": "mca",
  "/staff/deal/:id": "mca",
  "/staff/funds-requests/:wildcard*": "mca",
  "/staff/tranched-advance-deployments": "mca",
  "/staff/forecasting-queue": "product-services",
  "/staff/forecasting-details": "product-services",
  "/staff/wayflyercompanies": "mca",
  "/staff/wayflyercompany/:id": "mca",
  "/staff/wayflyercompany/:id/contracts/:wildcard*": "mca",
  "/staff/wayflyercompany/:id/payments/:wildcard*": "mca",
  "/staff/wayflyercompany/:id/advances/:wildcard*": "mca",
  "/staff/wayflyercompany/:id#Underwriting": "mca",
  "/staff/wayflyercompany/:id#Onboarding": "mca",
  "/staff/wayflyercompany/:id#Users": "platform",
  "/staff/wayflyercompany/:id#Financing": "unknown",
  "/staff/wayflyercompany/:id#Discovery": "unknown",
  "/staff/wayflyercompany/:id#Clearbit": "unknown",
  "/staff/wayflyercompany/:id#Offers": "product-services",
  "/staff/wayflyercompany/:id#Contracts": "mca",
  "/staff/wayflyercompany/:id#Analysis": "unknown",
  "/staff/wayflyercompany/:id#Direct%20Debit%20": "mca",
  "/staff/wayflyercompany/:id#Bank%20Accounts": "mca",
  "/staff/bsc/:wildcard*": "mca",
  "/staff/offers/:wildcard*": "product-services",
  "/staff/cash-advances/:wildcard*": "mca",
  "/staff/kyc-details/:wildcard*": "mca",
  "/staff/kyc-queue/:wildcard*": "mca",
  "/staff/v2/company/:wildcard*": "mca",
  "/staff/v2/connections/:wildcard*": "mca",
  "/staff/v2/underwriting-home": "product-services",
  "/staff/v2/platform/tasks": "platform",
  "/staff/v2/platform/users": "platform",
  "/staff/v2/platform/partners": "growthacq",
  "/staff/v2/partners/:wildcard*": "growthacq",
  "/staff/v2/platform/business": "unknown",
  "/staff/v2/waypay/:wildcard*": "mca",
  "/funding/how-to-apply": "mca",
  "/company-creation": "platform",
  "/verify-identity/:token": "mca",
  "/:slug/benchmarks/:wildcard*": "growthacq",
  "/:slug/funding/application/:wildcard*": "mca",
  "/:slug/funding/advance-application": "mca",
  "/:slug/funding/request-checklist/:wildcard*": "mca",
  "/:slug/funding/getting-started/company-details": "mca",
  "/:slug/funding/upload-documents": "mca",
  "/:slug/funding/verify-business": "mca",
  "/:slug/funding/getting-started": "mca",
  "/:slug/financing/:wildcard*": "mca",
  "/:slug/financing/statements": "mca",
  "/:slug/financing/bank-statements": "mca",
  "/:slug/financing/funds-request": "mca",
  "/:slug/verify-business": "mca",
  "/:slug/financing-contract-bank-details": "mca",
  "/:slug/funder": "mca",
  "/:slug/settings": "mca",
  "/:slug/settings/connections": "mca",
  "/:slug/performance/:wildcard*": "mca",
  "/:slug/waypay/:wildcard*": "mca",
  "/:slug/financial-os/:wildcard*": "npd",
  "/:slug/remediation": "platform",
  "/auth/:wildcard*": "product-services",
};

export const useFindViewOwner = (): string => {
  const { pathname, hash } = window.location;
  const [viewOwner, setViewOwner] = useState(
    findOwnerForRoute({ ownersMap: viewPathsOwners, displayOwnersMap: displayOwners, pathname, hash }).owner
  );
  useEffect(() => {
    const env = import.meta.env.PROD && !window.origin.includes("dev") ? "prod" : "local";
    setViewOwner(
      findOwnerForRoute({ ownersMap: viewPathsOwners, displayOwnersMap: displayOwners, pathname, hash }).owner
    );
    // eslint-disable-next-line no-console
    env !== "prod" && console.info("ROUTE OWNER: ", viewOwner);
  }, [pathname, hash, viewOwner]);

  return viewOwner;
};
