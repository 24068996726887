import { Button, ButtonGroup, Flex, Modal, ResourceList, Spinner, Text } from "@wayflyer/flyui";
import { IconDeviceMobile24Line, IconQrCode24Line } from "@wayflyer/flyui-icons/24/line";
import type { IntlShape } from "react-intl";
import { useIntl } from "react-intl";

import { type AuthnEnrolledMfa, AuthnMfaMethod } from "../../authn/client";

import { MFA_MESSAGES } from "./mfa-messages";

const MFATypeLabels: Record<AuthnMfaMethod, string> = {
  [AuthnMfaMethod.EMAIL]: "Email",
  [AuthnMfaMethod.OTP]: "Authenticator App",
  [AuthnMfaMethod.PHONE]: "SMS",
  [AuthnMfaMethod.WEBAUTHN_PLATFORM]: "Biometrics",
};

const MFATypeDetails: Record<AuthnMfaMethod, { id: string; defaultMessage: string }> = {
  [AuthnMfaMethod.EMAIL]: { id: "", defaultMessage: "" }, // not a type we surface to customers
  [AuthnMfaMethod.OTP]: MFA_MESSAGES.otpHelperText,
  [AuthnMfaMethod.PHONE]: MFA_MESSAGES.smsHelperText,
  [AuthnMfaMethod.WEBAUTHN_PLATFORM]: { id: "", defaultMessage: "" }, // biometrics are not surfaced to customers yet
};

interface ShowMFAMethodsProps {
  isFetching: boolean;
  currentMfaApiToken?: string;
  enrolledMfaMethods: AuthnEnrolledMfa[];
  setMethodToRemove: (method: AuthnEnrolledMfa | null) => void;
  enroll: (args: { mfaMethod: AuthnMfaMethod; next?: string }) => Promise<void>;
  redirectTo: string;
  methodToRemove: AuthnEnrolledMfa | null;
  removeMfaMethod: (id: string) => Promise<void>;
  stepUpMfaApi: (args?: { next?: string }) => Promise<void>;
  smallText?: boolean;
}

interface ShowMFADetailsProps {
  intl: IntlShape;
  enrolledMfaMethods: AuthnEnrolledMfa[];
  setMethodToRemove: (method: AuthnEnrolledMfa | null) => void;
  enroll: (args: { mfaMethod: AuthnMfaMethod; next?: string }) => Promise<void>;
  redirectTo: string;
  methodToRemove: AuthnEnrolledMfa | null;
  removeMfaMethod: (id: string) => Promise<void>;
}

interface MFADetailsProps {
  mfaMethod: AuthnMfaMethod;
  icon: React.ReactNode;
  enrolledMfaMethods: AuthnEnrolledMfa[];
  setMethodToRemove: (method: AuthnEnrolledMfa | null) => void;
  enroll: (args: { mfaMethod: AuthnMfaMethod; next?: string }) => Promise<void>;
  redirectTo: string;
}

export const MfaDetails = ({
  mfaMethod,
  icon,
  enrolledMfaMethods,
  setMethodToRemove,
  enroll,
  redirectTo,
}: MFADetailsProps) => {
  const { formatMessage: intlMsg } = useIntl();

  const enrolledMfaMethod = enrolledMfaMethods.find((m) => m.type === mfaMethod && m.active);
  return (
    <>
      {enrolledMfaMethod ? (
        <ResourceList.NavigationActionItem
          label={MFATypeLabels[mfaMethod]}
          icon={icon}
          status="success"
          statusText={intlMsg(MFA_MESSAGES.enabled)}
          detailsText={
            <Button
              onClick={() => setMethodToRemove(enrolledMfaMethod)}
              variant="Tertiary"
              alignLabel="left"
              size="small"
            >
              {intlMsg(MFA_MESSAGES.remove)}
            </Button>
          }
        />
      ) : (
        <ResourceList.NavigationActionItem
          label={MFATypeLabels[mfaMethod]}
          icon={icon}
          status="incomplete"
          statusText={intlMsg(MFA_MESSAGES.add)}
          detailsText={intlMsg(MFATypeDetails[mfaMethod])}
          action={{
            onClick: () => enroll({ mfaMethod, next: redirectTo }),
          }}
        />
      )}
    </>
  );
};

const ShowMfaDetailsComponent = ({
  intl,
  enrolledMfaMethods,
  setMethodToRemove,
  enroll,
  redirectTo,
  methodToRemove,
  removeMfaMethod,
}: ShowMFADetailsProps) => {
  // Display the pre-defined MFA methods to the user
  const mfaMethods = (
    <ResourceList>
      <MfaDetails
        enrolledMfaMethods={enrolledMfaMethods}
        setMethodToRemove={setMethodToRemove}
        enroll={enroll}
        mfaMethod={AuthnMfaMethod.PHONE}
        icon={<IconDeviceMobile24Line />}
        redirectTo={redirectTo}
      />
      <MfaDetails
        enrolledMfaMethods={enrolledMfaMethods}
        setMethodToRemove={setMethodToRemove}
        enroll={enroll}
        mfaMethod={AuthnMfaMethod.OTP}
        icon={<IconQrCode24Line />}
        redirectTo={redirectTo}
      />
    </ResourceList>
  );

  // Modal to ensure user wants to remove MFA method
  const removeModal = methodToRemove ? (
    <Modal
      isOpen={true}
      title={intl.formatMessage(MFA_MESSAGES.removeMfaMethodTitle)}
      primaryAction={{
        content: intl.formatMessage(MFA_MESSAGES.remove),
        onAction: () => removeMfaMethod(methodToRemove.id),
      }}
      secondaryAction={{
        content: intl.formatMessage(MFA_MESSAGES.cancel),
        onAction: () => setMethodToRemove(null),
      }}
    >
      <Text>
        {intl.formatMessage(MFA_MESSAGES.removeMfaConfirmation, {
          label: MFATypeLabels[methodToRemove.type],
          b: (text: string) => (
            <Text fontWeight="semiBold" element="span">
              {text}
            </Text>
          ),
        })}
      </Text>
    </Modal>
  ) : null;

  return (
    <Flex direction="column" gap="2">
      {mfaMethods}
      {removeModal}
    </Flex>
  );
};

export const ShowMFAMethodsComponent = ({
  isFetching,
  currentMfaApiToken,
  enrolledMfaMethods,
  setMethodToRemove,
  enroll,
  redirectTo,
  methodToRemove,
  removeMfaMethod,
  stepUpMfaApi,
  smallText = false,
}: ShowMFAMethodsProps) => {
  const intl = useIntl();

  return isFetching ? (
    <Spinner size={32} />
  ) : currentMfaApiToken === undefined ? (
    <Flex direction="column" gap="2">
      {smallText ? (
        <Text size="sm">{intl.formatMessage(MFA_MESSAGES.mfaTokenIsExpired)}</Text>
      ) : (
        <Text>{intl.formatMessage(MFA_MESSAGES.mfaTokenIsExpired)}</Text>
      )}
      <ButtonGroup
        primaryAction={{
          onAction: () => stepUpMfaApi({ next: redirectTo }),
          content: intl.formatMessage(MFA_MESSAGES.refreshMFAToken),
        }}
      />
    </Flex>
  ) : (
    <ShowMfaDetailsComponent
      intl={intl}
      enrolledMfaMethods={enrolledMfaMethods}
      setMethodToRemove={setMethodToRemove}
      enroll={enroll}
      redirectTo={redirectTo}
      methodToRemove={methodToRemove}
      removeMfaMethod={removeMfaMethod}
    />
  );
};
