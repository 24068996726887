import * as React from "react";
import { createContext, useContext } from "react";

export type ConsentContextValue = {
  consentHandler: () => Promise<void>;
};

export interface ConsentContextProps extends ConsentContextValue {
  children: any;
}

export const ConsentContext = createContext<ConsentContextValue>({
  consentHandler: () => Promise.resolve(),
});

export function ConsentContextProvider({ consentHandler, children }: ConsentContextProps) {
  return <ConsentContext.Provider value={{ consentHandler }}>{children}</ConsentContext.Provider>;
}

export const useConsentHandler = (): ConsentContextValue => useContext(ConsentContext);
