import { Flex, Grid, Spinner, Text } from "@wayflyer/flyui";
import { styled } from "styled-components";

type OfferPropertyProps = {
  title: string;
  subtext?: string;
  value: string | React.ReactNode;
  subvalue?: string;
  isLoading?: boolean;
  inverseFormat?: boolean;
};

export type AccordionOfferPropertyNextProps = OfferPropertyProps & { body: React.ReactNode };

const OfferPropertyGrid = styled(Grid)`
  width: 100%;
  justify-content: space-between;
`;

export const OfferProperty = ({
  title,
  subtext,
  value,
  subvalue,
  isLoading = false,
  inverseFormat = false,
}: OfferPropertyProps) => (
  <OfferPropertyGrid columns="minmax(auto, 500px) minmax(100px, 300px)">
    <Flex direction="column" gap="1">
      <Text fontWeight={inverseFormat ? "semiBold" : "regular"} color="heading">
        {title}
      </Text>
      {!!subtext && <Text size="sm">{subtext}</Text>}
    </Flex>
    <Flex direction="column" gap="1" margin={["0", "0", "0", "auto"]}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Text fontWeight={inverseFormat ? "regular" : "semiBold"} color="heading" align="end">
          {value}
        </Text>
      )}
      {!!subvalue && <Text size="sm" fontStyle="italic" align="end">{`(${subvalue})`}</Text>}
    </Flex>
  </OfferPropertyGrid>
);
