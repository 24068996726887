import { FundingApplicationSchemas, ProductRequestSchemas } from "api-types";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

// Get relavant incomplete customer tasks
export const getIncompleteCustomerTasks = (
  fundsRequestData: ProductRequestSchemas.FundsRequestInfoResponse,
  airsData: FundingApplicationSchemas.AdditionalInformationRequestWithMetaSchema[]
) => {
  if (!fundsRequestData.alwaysOnOfferEnabled) {
    return [];
  }

  // Assume submitted application if not in start_subsequent_funding step
  const hasSubmittedSubsequentFundingApplication = !!(
    fundsRequestData.currentStep && fundsRequestData.currentStep !== "start_subsequent_funding"
  );

  const airNotifications = (() => {
    const base = airsData.filter((air) => air.status === "in_progress") ?? [];

    // 6 months of banking data via statement could become noisy for customers not looking for additional funding
    // So suppress these AIRs until they have submitted a subsequent funding application
    if (!hasSubmittedSubsequentFundingApplication) {
      return base.filter((air) => air.type !== "bank_statements_for_account");
    }
    return base;
  })();

  return airNotifications;
};
