import { requestPost } from "../request";

export type FileUploadOptions = {
  createUrlPath: string;
  confirmUploadPath: string;
  companyId?: number;
  id?: number;
};

/**
 * request to our backend asking for a presigned post URL for S3
 * file name, size and type are included and in the backend which can be added
 * as arguments to the presigned post URL
 */
export const createUploadUrl = ({ name, size, type }, path, companyId, id) =>
  requestPost(path, { companyId, id, name, size, type });

/**
 * multipart upload to S3
 * urlOptions has a bunch of AWS Specific fields (e.g. access key, signatures)
 * that come back from the create_presigned_post_url method in the backend
 */
export const postFile = (file, urlOptions) => {
  const form = new FormData();
  Object.keys(urlOptions.fields).forEach((key) => form.append(key, urlOptions.fields[key]));
  form.append("Content-type", file.type);
  form.append("file", file);
  return fetch(urlOptions.url, {
    method: "POST",
    headers: {},
    // @todo: remove when `msw` is upgraded to v2
    // v1 chokes on the form data
    ...(process.env.NODE_ENV === "test" ? {} : { body: form }),
  });
};

// confirm that an upload to S3 was successful
export const confirmUpload = (confirmUploadPath, objectKey, id) => requestPost(confirmUploadPath, { objectKey, id });

/**
 * Full process for uploading document
 *
 * 1. Request to the backend to get a presigned POST url.
 * 2. Upload file to S3 using the presigned URL.
 * 3. Request to the backend to confirm that the file was uploaded.
 */
export const uploadDocument = async (
  file: File,
  { createUrlPath, confirmUploadPath, companyId, id }: FileUploadOptions
) => {
  const { objectKey, urlOptions } = await createUploadUrl(file, createUrlPath, companyId, id);

  if (!objectKey) {
    throw new Error("createUploadUrl result does not have an object key");
  }

  const uploadResult = await postFile(file, urlOptions);

  if (!uploadResult.ok) {
    throw new Error(`postFile result has status: ${uploadResult.status}: ${uploadResult.statusText}`);
  }

  return confirmUpload(confirmUploadPath, objectKey, id);
};
