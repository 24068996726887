export interface WfAuth0Config {
  readonly clientId: string;
  readonly domain: string;
  readonly tenantDomain: string;
}

export function createWfAuth0ConfigFromEnv(): WfAuth0Config {
  if (!import.meta.env.VITE_WF_AUTH0_CONFIG) {
    throw new Error("VITE_WF_AUTH0_CONFIG is missing");
  }

  return JSON.parse(import.meta.env.VITE_WF_AUTH0_CONFIG) as WfAuth0Config;
}

let auth0Config: WfAuth0Config | undefined;

/**
 * Returns the Auth0 configuration from the Vite environment.
 */
export function getWfAuth0Config(): WfAuth0Config {
  if (!auth0Config) {
    auth0Config = createWfAuth0ConfigFromEnv();
  }
  return auth0Config;
}
