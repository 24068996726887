import { Flex, List, Text } from "@wayflyer/flyui";
import type { FundingApplicationSchemas } from "api-types";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { FormattedMessage, type IntlShape } from "react-intl";

import { getEndDateForPeriodText } from "../../..";
import { MESSAGES } from "../message-definitions";

dayjs.extend(LocalizedFormat);

const getBankStatementsDetailsPeriodsList = (
  intl: IntlShape,
  periods: FundingApplicationSchemas.DateTimeRangeSchema[]
): React.ReactNode => {
  return (
    <List>
      {periods.map((period) => (
        <List.Item key={`${period.start}-${period.end}`}>
          <Text size="sm">
            {intl.formatMessage(MESSAGES.periodText, {
              dateFrom: dayjs(period.start).startOf("month").format("LL"),
              dateTo: getEndDateForPeriodText(period.end).format("LL"),
            })}
          </Text>
        </List.Item>
      ))}
    </List>
  );
};

/**
 * Returns a formatted string representing the air periods.
 * @param capitalise - capitalize the first letter of the message.
 */
const getAirPeriodsText = (
  intl: IntlShape,
  periods: FundingApplicationSchemas.DateTimeRangeSchema[],
  capitalise = false
): string => {
  return periods.reduce((text, period, index) => {
    text += index > 0 && index < periods.length - 1 ? ", " : "";
    text += intl.formatMessage(capitalise ? MESSAGES.airTitlePeriodsText : MESSAGES.airDetailsPeriodsText, {
      startPeriod: dayjs(period.start).startOf("month").format("LL"),
      endPeriod: getEndDateForPeriodText(period.end).format("LL"),
    });
    return text;
  }, "");
};

export const getBankStatementsDetailsText = (
  intl: IntlShape,
  airData: FundingApplicationSchemas.BankStatementsForAccountAIRSchema
) => {
  return airData.periods.length > 1 ? (
    <Flex direction="column">
      {airData.bank_account_mask ? (
        <FormattedMessage
          id="shared-ui: air bank statements required text details"
          defaultMessage="<p>{bankName} account ending in {accountNum} for the periods:</p>"
          values={{
            p: (chunks: any) => <Text size="sm">{chunks}</Text>,
            bankName: airData.bank_name ? airData.bank_name : intl.formatMessage(MESSAGES.defaultDetailsTextBankName),
            accountNum: airData.bank_account_mask,
          }}
        />
      ) : (
        <FormattedMessage
          id="shared-ui: air bank statements required text details no mask"
          defaultMessage="<p>{bankName} account(s) for the periods:</p>"
          values={{
            p: (chunks: any) => <Text size="sm">{chunks}</Text>,
            bankName: airData.bank_name,
          }}
        />
      )}
      {getBankStatementsDetailsPeriodsList(intl, airData.periods)}
    </Flex>
  ) : airData.bank_account_mask ? (
    intl.formatMessage(MESSAGES.airBankStatementsDetailsText, {
      bankName: airData.bank_name ? airData.bank_name : intl.formatMessage(MESSAGES.defaultDetailsTextBankName),
      accountNum: airData.bank_account_mask,
      periods: getAirPeriodsText(intl, airData.periods),
    })
  ) : (
    intl.formatMessage(MESSAGES.airBankStatementsDetailsTextNoMask, {
      bankName: airData.bank_name,
      periods: getAirPeriodsText(intl, airData.periods),
    })
  );
};
