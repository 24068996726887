import { useCallback, createContext, useContext } from "react";
import { type Action, FlyErrorModalProvider, FlyError, useFlyErrorModal } from "@wayflyer/flyui";
import { addError } from "@wayflyer/blackbox-fe";
import { useIntl } from "react-intl";
import { getAppName } from "shared-utils";

import { type ErrorVariant, getCustomerErrorPresetMap, getStaffErrorPresetMap } from "./error-presets";

type ErrorPreset = {
  /**
   * The error that will be logged
   */
  error: Error;
  /**
   * The variant of error to display, see frontend/packages/shared-ui/src/error-handling/error-variants.tsx
   */
  variant: ErrorVariant;
  /**
   * Override the default primary action for this variant
   */
  primaryAction?: Action;
  /**
   * Override the default secondary action for this variant
   */
  secondaryAction?: Action;
};

export type ErrorModalContextValue = {
  /**
   * Opens an error modal and logs the error
   *
   * Pass a FlyError to display a custom error message, or
   * pass an ErrorPreset to display a predefined error message
   */
  openErrorModal: (params: InstanceType<typeof FlyError> | ErrorPreset) => void;
  /**
   * Closes the error modal if it is open
   */
  closeErrorModal: () => void;
};

export interface AppErrorModalProviderProps {
  children: React.ReactNode;
}

const ProviderMissingError = new Error("Provider not found, components should be wrapped in AppErrorModalProvider");

export const ErrorModalContext = createContext<ErrorModalContextValue>({
  openErrorModal: () => {
    throw ProviderMissingError;
  },
  closeErrorModal: () => {
    throw ProviderMissingError;
  },
});

const ErrorModalContextWrapper = ({ children }) => {
  const intl = useIntl();
  const isCustomerFacing = getAppName() === "customer-app";
  const variantMap = isCustomerFacing ? getCustomerErrorPresetMap({ intl }) : getStaffErrorPresetMap();
  const { openErrorModal: openFlyErrorModal, closeErrorModal, errorModalIsOpen } = useFlyErrorModal();
  const openErrorModal = useCallback(
    (params: InstanceType<typeof FlyError> | ErrorPreset) => {
      if (errorModalIsOpen) {
        return;
      }

      if (params instanceof FlyError) {
        return openFlyErrorModal(params);
      }

      const { variant, primaryAction, secondaryAction, error } = params;

      // eslint-disable-next-line no-console
      !variantMap[variant] && console.warn(`Unknown error variant: ${variant}`);

      const errorVariant = variantMap[variant] ?? variantMap.unknown;
      const {
        title,
        message,
        primaryAction: defaultPrimaryAction,
        secondaryAction: defaultSecondaryAction,
      } = errorVariant;
      return openFlyErrorModal(
        new FlyError({
          title,
          message,
          primaryAction: primaryAction ?? defaultPrimaryAction,
          secondaryAction: secondaryAction ?? defaultSecondaryAction,
          error,
        })
      );
    },
    [openFlyErrorModal, variantMap, errorModalIsOpen]
  );

  return children({ openErrorModal, closeErrorModal });
};

/**
 * Wraps FlyErrorModalProvider to add support for predefined / translated error messages
 */
export const AppErrorModalProvider = ({ children }: AppErrorModalProviderProps) => {
  return (
    <FlyErrorModalProvider logError={addError}>
      <ErrorModalContextWrapper>
        {({ openErrorModal, closeErrorModal }) => (
          <ErrorModalContext.Provider
            value={{
              openErrorModal,
              closeErrorModal,
            }}
          >
            {children}
          </ErrorModalContext.Provider>
        )}
      </ErrorModalContextWrapper>
    </FlyErrorModalProvider>
  );
};

/**
 * Wraps useFlyErrorModal to add support for preset error messages
 */
export const useErrorModal = (): ErrorModalContextValue => useContext(ErrorModalContext);
