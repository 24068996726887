import { type UseSuspenseQueryResult, useSuspenseQuery } from "@tanstack/react-query";
import { axiosClient } from "@wayflyer/auth-sdk";
import { ProductRequestSchemas } from "api-types";
import { useCustomerAppConfig } from "undercarriage";

export const useGetFundsRequestInfoQuery =
  (): UseSuspenseQueryResult<ProductRequestSchemas.FundsRequestInfoResponse> => {
    const {
      data: { customerDomain },
    } = useCustomerAppConfig();

    return useSuspenseQuery(getFundsRequestInfoQueryOptions(customerDomain));
  };

export const getFundsRequestInfoQueryOptions = (customerDomain: string) => {
  const url = `/${customerDomain}/product-request/api/funds-request-info`;

  return {
    queryKey: [url],
    queryFn: () => axiosClient.get<ProductRequestSchemas.FundsRequestInfoResponse>(url),
    select: ({ data }): ProductRequestSchemas.FundsRequestInfoResponse => data,
  };
};
