import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosProvider } from "@wayflyer/auth-sdk";
import { addError, BlackBox } from "@wayflyer/blackbox-fe";
import { FlyUIProvider, NavigationProvider, queryClient, useClientNavigation } from "@wayflyer/flyui";
import { BankConnectConsentProvider, ConnectorProvider, InviteModal } from "@wayflyer/shared-ui";
import { BaseSchemas } from "api-types";
import { Suspense, useState } from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider, useIntl } from "react-intl";
import { RouterProvider, useNavigate } from "react-router-dom";
import {
  analytics,
  AppErrorModalProvider,
  CompanyInactiveModal,
  ErrorComponent,
  flyUIAnalyticsHandler,
  FullScreenLoader,
  getCustomerErrorPresetMap,
  InviteProvider,
  WfAuthnProvider,
  PHProvider,
  setRefreshPreview,
  useFindViewOwner,
  usePostHogPageView,
  WfAuth0ProviderFromViteConfig,
} from "shared-ui";
import { AnalyticsProvider } from "shared-utils";
import { useCustomerAppConfig } from "undercarriage";
import type { AxiosError } from "axios";

import { router } from "../../router";
import { usePostHogIdentify } from "../analytics/posthog";
import { collectPrivacyPolicyConsent } from "../consent";
import { ConsentContextProvider } from "../context-providers/ConsentContext";
import { convertNumDaysToDateRange, getNumDaysSinceDate } from "../utils/date-helpers";

import { useNavigationItems as useNavigationNextItems } from "./navigation/use-navigation-items";

const messagesCache = {};

// Acholi is a magic language for enabling the Crowdin in context editor
// It is a real language, so we might one day need to change, but I doubt it
// See https://support.crowdin.com/enterprise/in-context/
const getLocale = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.get("crowdin_editor") === "true") {
    return "ach";
  }
  return navigator.language;
};

const getDatadogUserContext = (appConfig: BaseSchemas.CustomerAppConfig) => {
  const { analyticsAttributes, userName, customerName } = appConfig;
  const lastLoginInDays = analyticsAttributes.lastLogin && getNumDaysSinceDate(analyticsAttributes.lastLogin);
  const lastLoginDateRange = lastLoginInDays && convertNumDaysToDateRange(lastLoginInDays);
  const deviceResolution = `${window.screen.width}x${window.screen.height}`;
  const isValidSessionRedirect = !!sessionStorage.getItem("validSessionRedirect");
  return {
    id: analyticsAttributes.userId,
    name: userName,
    isStaff: analyticsAttributes.isStaff,
    lastLogin: analyticsAttributes.lastLogin,
    lastLoginInDays,
    lastLoginDateRange,
    createdAt: analyticsAttributes.createdAt,
    customerName,
    deviceResolution,
    validSessionRedirect: isValidSessionRedirect,
    email: analyticsAttributes.emailTemplate,
    isInternalUser: analyticsAttributes.emailTemplate?.includes("@wayflyer.com"),
    ...analyticsAttributes,
  };
};

function getMessages(locale) {
  if (messagesCache[locale]) {
    return messagesCache[locale];
  }
  throw loadMessages(locale);
}

async function loadMessages(locale) {
  let messages;
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (locale.startsWith("es")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    messages = await import("../../../compiled-lang/es.json");
  } else if (locale.startsWith("de")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    messages = await import("../../../compiled-lang/de.json");
  } else if (urlSearchParams.get("crowdin_editor") === "true") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    messages = await import("../../../compiled-lang/ach.json");
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    messages = await import("../../../compiled-lang/en.json");
  }

  messagesCache[locale] = messages.default;
  return messages.default;
}

const AsyncIntlProvider = ({ children }) => {
  const locale = getLocale();
  const messages = getMessages(locale);

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale="en" onError={() => {}}>
      {children}
    </IntlProvider>
  );
};

export const RootContainer = ({ children, ...props }) => {
  const { data: appConfig } = useCustomerAppConfig();
  const { previewEnabled } = setRefreshPreview();
  useFindViewOwner();
  const intl = useIntl();
  const { bankConnectProps } = props;
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const { clientNavigateFn } = useClientNavigation();
  const navigationNextProps = useNavigationNextItems({ appConfig, setInviteModalOpen, clientNavigateFn });
  const { analyticsAttributes } = appConfig;
  usePostHogIdentify(appConfig);
  usePostHogPageView();

  return (
    <AnalyticsProvider value={appConfig.analyticsAttributes}>
      <InviteProvider homePath={appConfig.homePath}>
        {({ inviteHandler }) => (
          <FlyUIProvider
            intl={intl}
            theme={previewEnabled ? "wayflyerNext" : "wayflyer"}
            analyticsHandler={flyUIAnalyticsHandler(analyticsAttributes)}
          >
            <AppErrorModalProvider>
              <NavigationProvider {...navigationNextProps}>
                <CompanyInactiveModal isOpen={appConfig.customerInactive} />
                <ConnectorProvider>
                  <InviteModal
                    inviteHandler={inviteHandler}
                    isOpen={inviteModalOpen}
                    close={() => setInviteModalOpen(false)}
                  />
                  <BankConnectConsentProvider
                    consentNeeded={bankConnectProps?.consentNeeded}
                    consentHandler={() => {
                      return collectPrivacyPolicyConsent(appConfig.homePath);
                    }}
                  >
                    <ConsentContextProvider
                      consentHandler={() => {
                        return collectPrivacyPolicyConsent(appConfig.homePath);
                      }}
                    >
                      {children}
                    </ConsentContextProvider>
                  </BankConnectConsentProvider>
                </ConnectorProvider>
              </NavigationProvider>
            </AppErrorModalProvider>
          </FlyUIProvider>
        )}
      </InviteProvider>
    </AnalyticsProvider>
  );
};

const getRootNode = () => {
  const rootNode = document.getElementById("app-container");
  if (!rootNode) {
    throw new Error("Root node not found");
  }
  return createRoot(rootNode);
};

const ErrorFallback = ({ resetErrorBoundary, variant }) => {
  const intl = useIntl();
  const { title, message, primaryAction } = getCustomerErrorPresetMap({ intl })[variant];
  return (
    <AxiosProvider>
      <QueryClientProvider client={queryClient}>
        <RootContainer>
          <ErrorComponent
            {...{
              title,
              message,
              primaryAction,
              secondaryAction: {
                content: intl.formatMessage({ id: "error.goBack", defaultMessage: "Go back" }),
                onAction: resetErrorBoundary,
              },
            }}
          />
        </RootContainer>
      </QueryClientProvider>
    </AxiosProvider>
  );
};

export function RootComponent(props) {
  return (
    <Suspense
      fallback={
        <FlyUIProvider theme="wayflyer">
          <FullScreenLoader />
        </FlyUIProvider>
      }
    >
      <PHProvider>
        <QueryClientProvider client={queryClient}>
          <AxiosProvider>
            <AppTree {...props} />
          </AxiosProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PHProvider>
    </Suspense>
  );
}

export function renderRootComponent(props) {
  const root = getRootNode();

  root.render(<RouterProvider router={router} />);
  try {
    analytics.pageView(props.analyticsAttributes);
  } catch (e) {
    addError(e, { ...props.analyticsAttributes });
  }
}

export function AppTree(props) {
  const navigate = useNavigate();
  const { data: appConfig } = useCustomerAppConfig({
    meta: {
      onError: (error: AxiosError) => {
        // fetch happens outside of the error boundary, so we need to handle the error here
        addError(error);
        navigate("/500");
      },
    },
  });
  return (
    <AsyncIntlProvider>
      <BlackBox FallbackComponent={ErrorFallback} user={getDatadogUserContext(appConfig)}>
        <WfAuth0ProviderFromViteConfig>
          <WfAuthnProvider>
            <RootContainer {...props} />
          </WfAuthnProvider>
        </WfAuth0ProviderFromViteConfig>
      </BlackBox>
    </AsyncIntlProvider>
  );
}

// We want to keep the rendering of our error components as simple as possible to avoid
// the Error components throwing errors themselves. Avoid wrapping/mounting with unnecessary providers.
export function renderErrorComponent(Component: React.ComponentType, props) {
  const root = getRootNode();
  root.render(
    <AsyncIntlProvider>
      <FlyUIProvider theme="wayflyer">
        <Component {...props} />
      </FlyUIProvider>
    </AsyncIntlProvider>
  );
}
