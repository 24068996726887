import { createContext, useCallback, useContext } from "react";
import { requestPost, useAnalyticsContext } from "shared-utils";
import { addError } from "@wayflyer/blackbox-fe";

import { analytics } from "../analytics";

export type InviteContextValue = {
  inviteHandler: (email: string) => Promise<void>;
};

export interface InviteContextProps {
  homePath: string;
  children: any;
}

const InviteContext = createContext<InviteContextValue>({
  inviteHandler: () => Promise.reject("Something went wrong, please contact support"),
});

const errorMap = {
  "Email already exists": "Invite - Invitation error - Email already exists",
  "Rate limit reached": "Invite - Invitation error - Rate limit reached",
  "internal server error": "Something went wrong, please contact support",
};

const sendInvite = async (homePath, email) => {
  try {
    if (!homePath.length) {
      throw Error("No homePath available when attempting to send invite to: " + email);
    }
    await requestPost(`${homePath}invite/`, { email });
  } catch (error) {
    addError(error);
    throw errorMap[error as string] || "Invite - Invitation error";
  }
};

export function InviteProvider({ homePath, children }: InviteContextProps) {
  const analyticsContext = useAnalyticsContext();
  const inviteHandler = useCallback(
    (email: string) => {
      analytics.inviteMembersSendClicked(analyticsContext);
      return sendInvite(homePath, email);
    },
    [analyticsContext, homePath]
  );
  return (
    <InviteContext.Provider value={{ inviteHandler }}>
      {typeof children === "function" ? children({ inviteHandler }) : children}
    </InviteContext.Provider>
  );
}

export const useInviteHandler = (): InviteContextValue => useContext(InviteContext);
