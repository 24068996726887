import { Auth0Client } from "@auth0/auth0-spa-js";
import type { GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import axios, { type AxiosInstance } from "axios";
import { jwtDecode } from "jwt-decode";

import { getWfAuth0Config, type WfAuth0Config } from "./config";

const CALLBACK_ROUTE = "/authn-wf/callback";
const MFA_SCOPES = "read:authenticators remove:authenticators";
const STEP_UP_SCOPES = "mfa_step_up";
const SUDO_SCOPES = "mfa_sudo";

export enum AuthnMfaMethod {
  EMAIL = "email",
  OTP = "otp",
  PHONE = "phone",
  WEBAUTHN_PLATFORM = "webauthn-platform",
}

export interface WfIdTokenClaims {
  readonly exp: number;
  readonly "urn:wayflyer:has_mfa_enrolled": boolean;
}

/**
 * An ID token.
 */
export class WfIdToken {
  readonly jwt: string;
  readonly expiresAt: Date;
  readonly hasMfaEnrolled: boolean;

  constructor(token: { jwt: string; expiresAt: Date; hasMfaEnrolled: boolean }) {
    this.expiresAt = token.expiresAt;
    this.hasMfaEnrolled = token.hasMfaEnrolled;
    this.jwt = token.jwt;
  }

  get expired(): boolean {
    return this.expiresIn < 0;
  }

  /**
   * Returns the number of milliseconds until the token expires.
   */
  get expiresIn(): number {
    return this.expiresAt.getTime() - Date.now();
  }

  static fromJwt(jwt: string): WfIdToken {
    const decoded = jwtDecode(jwt) as WfIdTokenClaims;

    return new WfIdToken({
      expiresAt: new Date(decoded.exp * 1000),
      hasMfaEnrolled: decoded["urn:wayflyer:has_mfa_enrolled"],
      jwt,
    });
  }
}

export interface WfAccessTokenClaims {
  readonly exp: number;
  readonly "urn:wayflyer:refresh_exp"?: number;
  readonly "urn:wayflyer:refresh_exp_idle"?: number;
}

/**
 * Details about the refresh token for an access token.
 */
export class WfRefreshTokenDetails {
  readonly expiresAt: Date;
  readonly expiresIdleAt: Date;

  constructor(details: { expiresAt: Date; expiresIdleAt: Date }) {
    this.expiresAt = details.expiresAt;
    this.expiresIdleAt = details.expiresIdleAt;
  }

  get expired(): boolean {
    return this.expiresIn < 0;
  }

  get expiredIdle(): boolean {
    return this.expiresIdleIn < 0;
  }

  get expiresIn(): number {
    return this.expiresAt.getTime() - Date.now();
  }

  get expiresIdleIn(): number {
    return this.expiresIdleAt.getTime() - Date.now();
  }
}

/**
 * An access token.
 */
export class WfAccessToken {
  readonly jwt: string;
  readonly expiresAt: Date;

  /**
   * Details about the refresh token associated with this access token, if available.
   */
  readonly refresh?: WfRefreshTokenDetails;

  constructor(token: { jwt: string; expiresAt: Date; refreshExpiresAt?: Date; refreshExpiresIdleAt?: Date }) {
    this.jwt = token.jwt;
    this.expiresAt = token.expiresAt;
    this.refresh =
      token.refreshExpiresAt && token.refreshExpiresIdleAt
        ? new WfRefreshTokenDetails({
            expiresAt: token.refreshExpiresAt,
            expiresIdleAt: token.refreshExpiresIdleAt,
          })
        : undefined;
  }

  get expired(): boolean {
    return this.expiresIn < 0;
  }

  /**
   * Returns the number of milliseconds until the token expires.
   */
  get expiresIn(): number {
    return this.expiresAt.getTime() - Date.now();
  }

  static fromJwt(jwt: string): WfAccessToken {
    const decoded = jwtDecode(jwt) as WfAccessTokenClaims;

    return new WfAccessToken({
      jwt,
      expiresAt: new Date(decoded.exp * 1000),
      refreshExpiresAt: decoded["urn:wayflyer:refresh_exp"]
        ? new Date(decoded["urn:wayflyer:refresh_exp"] * 1000)
        : undefined,
      refreshExpiresIdleAt: decoded["urn:wayflyer:refresh_exp_idle"]
        ? new Date(decoded["urn:wayflyer:refresh_exp_idle"] * 1000)
        : undefined,
    });
  }
}

/**
 * A client for interacting with Authn --- fetching step-up tokens, etc.
 */
export class WfAuthnClient {
  private readonly auth0Config: WfAuth0Config;
  private readonly auth0: Auth0Client;

  private readonly authParams: GetTokenSilentlyOptions["authorizationParams"];
  private readonly mfaApiAuthParams: GetTokenSilentlyOptions["authorizationParams"];
  private readonly stepUpAuthParams: GetTokenSilentlyOptions["authorizationParams"];
  private readonly sudoAuthParams: GetTokenSilentlyOptions["authorizationParams"];

  private idToken?: WfIdToken;
  private mfaApiToken?: WfAccessToken;
  private stepUpToken?: WfAccessToken;
  private sudoToken?: WfAccessToken;

  constructor(
    props: {
      /**
       * The Auth0 client instance to use.
       * @default a new instance
       */
      auth0Client?: Auth0Client;

      /**
       * The Auth0 configuration to use.
       * @default the configuration from the Vite environment
       */
      auth0Config?: WfAuth0Config;
    } = {}
  ) {
    this.auth0Config = props.auth0Config ?? getWfAuth0Config();
    this.auth0 =
      props.auth0Client ??
      new Auth0Client({
        ...this.auth0Config,
        authorizationParams: {
          audience: "wayflyer",
        },
        cacheLocation: "localstorage",
        useRefreshTokens: true,
        useRefreshTokensFallback: false,
      });

    this.authParams = {
      redirect_uri: window.location.origin + CALLBACK_ROUTE,
    };

    this.stepUpAuthParams = {
      ...this.authParams,
      scope: STEP_UP_SCOPES,
    };

    this.sudoAuthParams = {
      ...this.authParams,
      scope: SUDO_SCOPES,
    };

    this.mfaApiAuthParams = {
      ...this.authParams,
      audience: `https://${this.auth0Config.tenantDomain}/mfa/`,
      scope: MFA_SCOPES,
    };
  }

  /**
   * Completes the client-side portion of the authentication flow, having been to Auth0 and back, so that tokens are
   * available for use.
   *
   * This can only be called from the callback route.
   *
   * @returns The "next" path given at the start of the authentication flow
   */
  async handleRedirectCallback(): Promise<string> {
    if (window.location.pathname !== CALLBACK_ROUTE) {
      throw new Error("handleRedirectCallback called from wrong path");
    }

    const state = await this.auth0.handleRedirectCallback();
    if (!state.appState || !state.appState.next) {
      throw new Error("Invalid app state returned");
    }

    window.history.replaceState(null, "", window.location.pathname);
    return state.appState.next;
  }

  /**
   * Returns the ID token.
   *
   * This might be undefined if the user is not authenticated, e.g., unauthenticated pages or session has expired.
   */
  async getIdToken(
    args: {
      /**
       * Refresh the token instead of using the cached one.
       */
      refresh?: boolean;
    } = {}
  ): Promise<WfIdToken | undefined> {
    const token = await this.safeGetTokenSilently({
      authorizationParams: this.authParams,
      cacheMode: args.refresh ? "off" : "cache-only",
      detailedResponse: true,
    });

    if (!token) {
      this.idToken = undefined;
      return undefined;
    }

    if (this.idToken?.jwt === token.id_token) {
      return this.idToken;
    }

    this.idToken = WfIdToken.fromJwt(token.id_token);
    return this.idToken;
  }

  /**
   * Returns a valid step-up token if available.
   */
  async getStepUpToken(
    args: {
      /**
       * Refresh the token explicitly, otherwise the cached token will be used
       * if available.
       */
      refresh?: boolean;
    } = {}
  ): Promise<WfAccessToken | undefined> {
    const token = await this.safeGetTokenSilently({
      authorizationParams: this.stepUpAuthParams,
      cacheMode: args.refresh ? "off" : undefined,
    });

    if (!token) {
      this.stepUpToken = undefined;
      return undefined;
    }

    if (this.stepUpToken?.jwt === token.access_token) {
      return this.stepUpToken;
    }

    this.stepUpToken = WfAccessToken.fromJwt(token.access_token);
    return this.stepUpToken;
  }

  /**
   * Returns true if the user currently has a valid step-up token.
   */
  async hasStepUpToken(): Promise<boolean> {
    const token = await this.safeGetTokenSilently({
      authorizationParams: this.stepUpAuthParams,
      cacheMode: "cache-only",
    });

    return token !== undefined;
  }

  /**
   * Returns a valid sudo token if available.
   */
  async getSudoToken(): Promise<WfAccessToken | undefined> {
    const token = await this.safeGetTokenSilently({
      authorizationParams: this.sudoAuthParams,
      cacheMode: "cache-only",
    });

    if (!token) {
      this.sudoToken = undefined;
      return undefined;
    }

    if (this.sudoToken?.jwt === token.access_token) {
      return this.sudoToken;
    }

    this.sudoToken = WfAccessToken.fromJwt(token.access_token);
    return this.sudoToken;
  }

  /**
   * Returns true if the user currently has a valid sudo token.
   */
  async hasSudoToken(): Promise<boolean> {
    return (await this.getSudoToken()) !== undefined;
  }

  /**
   * Returns true if the user has any MFA method enrolled.
   *
   * This comes from the user's ID token. Pass `refresh: true` to force an ID token refresh after e.g., enrolling a new
   * MFA method.
   */
  async hasMfaEnrolled(opts: { refresh?: boolean } = {}): Promise<boolean | undefined> {
    const idToken = await this.getIdToken({ refresh: opts.refresh });
    if (!idToken) {
      return undefined;
    }

    return idToken.hasMfaEnrolled;
  }

  /**
   * Returns a valid MFA API token if one is available.
   */
  async getMfaApiToken(): Promise<WfAccessToken | undefined> {
    const token = await this.safeGetTokenSilently({
      authorizationParams: this.mfaApiAuthParams,
      cacheMode: "cache-only",
    });

    if (!token) {
      this.mfaApiToken = undefined;
      return undefined;
    }

    if (this.mfaApiToken?.jwt === token.access_token) {
      return this.mfaApiToken;
    }

    this.mfaApiToken = WfAccessToken.fromJwt(token.access_token);
    return this.mfaApiToken;
  }

  /**
   * Returns true if the user has a valid MFA API token.
   */
  async hasMfaApiToken(): Promise<boolean> {
    return (await this.getMfaApiToken()) !== undefined;
  }

  /**
   * Navigate the user to the Auth0 login page to enroll in a given MFA method.
   *
   * If the user is already enrolled in the given MFA method, they will immediately be redirected back to the given
   * `next` path.
   *
   * Prior to enrolling the new method, they will be prompted for any existing MFA method if they have one.
   *
   * @param method The MFA method to enroll in.
   * @param next An SPA path to navigate to after the enrollment is complete. Defaults to the current path (including
   * query).
   */
  async enroll(method: AuthnMfaMethod, next?: string) {
    return await this.auth0.loginWithRedirect({
      appState: WfAuthnClient.getAppState(next),
      authorizationParams: {
        ...this.authParams,
        enroll_mfa: method,
      },
    });
  }

  /**
   * Navigates the user to the Auth0 login page to perform a step-up authentication.
   *
   * - If the user has not enrolled in any MFA methods, they will be shown an error by Auth0.
   * - Otherwise, they will be prompted to complete an MFA challenge.
   *
   * The token lasts for 15 minutes and can be refreshed for up to 1 hour as long as it is continuously refreshed. If
   * the token expires, the user will need to step-up again.
   *
   * @param next An SPA path to navigate to after the step-up is complete. Defaults to the current path (including
   * query).
   */
  async stepUp(next?: string) {
    return await this.auth0.loginWithRedirect({
      appState: WfAuthnClient.getAppState(next),
      authorizationParams: this.stepUpAuthParams,
    });
  }

  /**
   * Navigates the user to the Auth0 login page to perform a step-up authentication for the MFA API.
   *
   * - If the user has no enrolled MFA methods, they will return immediately with a valid token.
   * - If the user has only enrolled WebAuthn, they will be prompted to enroll another method first.
   * - Otherwise, they will be prompted to complete an MFA challenge.
   *
   * The token lasts for 10 minutes and cannot be refreshed.
   *
   * @param next An SPA path to navigate to after the step-up is complete. Defaults to the current path (including
   * query).
   */
  async stepUpMfaApi(next?: string) {
    return await this.auth0.loginWithRedirect({
      appState: WfAuthnClient.getAppState(next),
      authorizationParams: this.mfaApiAuthParams,
    });
  }

  /**
   * Navigates the user to the Auth0 login page to perform sudo authentication.
   *
   * - If the user has not enrolled in any MFA methods, they will be shown an error by Auth0.
   * - Otherwise, they will be prompted to complete an MFA challenge.
   *
   * The token lasts for 15 minutes and cannot be refreshed.
   *
   * @param next An SPA path to navigate to after the step-up is complete. Defaults to the current path (including
   * query).
   */
  async sudo(next?: string) {
    return await this.auth0.loginWithRedirect({
      appState: WfAuthnClient.getAppState(next),
      authorizationParams: this.sudoAuthParams,
    });
  }

  /**
   * @see Auth0Client#getTokenSilently but returns undefined instead of throwing if:
   * - The user's session has expired.
   * - The refresh token has expired.
   * - The refresh token is missing.
   */
  private async safeGetTokenSilently(
    opts: GetTokenSilentlyOptions
  ): Promise<GetTokenSilentlyVerboseResponse | undefined> {
    try {
      return await this.auth0.getTokenSilently({
        ...opts,
        detailedResponse: true,
      });
    } catch (e: any) {
      if (e.error === "invalid_grant" || e.error === "login_required" || e.error === "missing_refresh_token") {
        return undefined;
      } else {
        throw e;
      }
    }
  }

  private static getAppState(next?: string) {
    return {
      next: next ?? window.location.pathname + window.location.search,
    };
  }
}

let clientInstance: WfAuthnClient | undefined;

/**
 * Returns the shared Authn client instance.
 */
export function getWfAuthnClient(): WfAuthnClient {
  if (!clientInstance) {
    clientInstance = new WfAuthnClient();
  }

  return clientInstance;
}

interface Auth0AuthenticatorsResponseEntry {
  readonly id: string;
  readonly active: boolean;
  readonly authenticator_type: AuthnMfaMethod;
  readonly name?: string;
  readonly oob_channel?: string;
}

/**
 * An enrolled MFA method.
 */
export interface AuthnEnrolledMfa {
  readonly id: string;
  readonly active: boolean;
  readonly name?: string;
  readonly type: AuthnMfaMethod;
}

const AUTH0_OOB_CHANNEL_TO_METHOD: Record<string, AuthnMfaMethod> = {
  email: AuthnMfaMethod.EMAIL,
  sms: AuthnMfaMethod.PHONE,
  "webauthn-platform": AuthnMfaMethod.WEBAUTHN_PLATFORM,
};

/**
 * A client for interacting with the Auth0 MFA API.
 *
 * A valid MFA API token is required for all operations. @see WfAuthnClient#stepUpMfaApi.
 */
export class WfAuthnMfaClient {
  private readonly auth0Config: WfAuth0Config;
  private readonly authnClient: WfAuthnClient;
  private readonly mfaApiClient: AxiosInstance;

  constructor(props?: {
    /**
     * The Auth0 configuration to use.
     * @default the configuration from the Vite environment
     */
    auth0Config?: WfAuth0Config;

    /**
     * The Authn client instance to use.
     * @default the singleton instance
     */
    authnClient?: WfAuthnClient;

    /**
     * The Axios instance to use for API requests.
     *
     * The base URL is assumed to be the MFA API URL.
     *
     * @default a new instance
     */
    mfaApiClient?: AxiosInstance;
  }) {
    this.auth0Config = props?.auth0Config ?? getWfAuth0Config();
    this.authnClient = props?.authnClient ?? getWfAuthnClient();
    this.mfaApiClient =
      props?.mfaApiClient ??
      axios.create({
        baseURL: `https://${this.auth0Config.tenantDomain}/mfa`,
      });
  }

  /**
   * Returns a list of enrolled MFA methods for the current user.
   *
   * This method will throw an error if the user does not have a valid MFA API token.
   */
  async get(): Promise<AuthnEnrolledMfa[]> {
    const res = await this.mfaApiClient.get("/authenticators", { headers: await this.getHeaders() });

    return res.data.map((entry: Auth0AuthenticatorsResponseEntry) => ({
      id: entry.id,
      active: entry.active,
      name: entry.name,
      type: entry.oob_channel
        ? AUTH0_OOB_CHANNEL_TO_METHOD[entry.oob_channel] ?? entry.authenticator_type
        : entry.authenticator_type,
    }));
  }

  /**
   * Removes an MFA method from the current user.
   *
   * This method will throw an error if the user does not have a valid MFA API token.
   */
  async remove(id: string) {
    await this.mfaApiClient.delete(`/authenticators/${id}`, { headers: await this.getHeaders() });
  }

  private async getHeaders() {
    const token = await this.authnClient.getMfaApiToken();

    if (!token) {
      throw new Error("No valid MFA API token.");
    }

    return {
      Authorization: `Bearer ${token.jwt}`,
    };
  }
}

let mfaClientInstance: WfAuthnMfaClient | undefined;

/**
 * Returns the shared Authn MFA client instance.
 */
export function getWfAuthnMfaClient(): WfAuthnMfaClient {
  if (!mfaClientInstance) {
    mfaClientInstance = new WfAuthnMfaClient();
  }

  return mfaClientInstance;
}
