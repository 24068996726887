import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { getAIRsDataQueryOptions, getFundsRequestInfoQueryOptions } from "shared-ui";

import { getIncompleteCustomerTasks } from "../../hooks/get-incomplete-customer-tasks";

// Wrapper function to get the count of task notifications for the customer
export const useNavTaskNotificationCount = (customerDomain: string): number => {
  const isTaskPageEnabled = useFeatureFlagEnabled("enable-tasks-page");
  const customerInCreation = customerDomain === "";

  const shouldCountNotifications = isTaskPageEnabled && !customerInCreation;

  const { data: fundsRequestData, isFetchedAfterMount: fundsRequestDataFetched } = useQuery({
    ...getFundsRequestInfoQueryOptions(customerDomain),
    enabled: shouldCountNotifications,
    placeholderData: keepPreviousData,
  });

  const { data: airsData, isFetchedAfterMount: airsDataFetched } = useQuery({
    ...getAIRsDataQueryOptions(customerDomain),
    enabled: shouldCountNotifications && fundsRequestDataFetched && fundsRequestData?.alwaysOnOfferEnabled, // For now, we only want to fetch AIRs if AOO customer
    placeholderData: keepPreviousData,
    refetchInterval: 120000, // Infrequent refetch interval e.g. 5 mins or so (will be refetching more frequently if in review screen)
  });

  const fundsRequestDataLoaded = !!(fundsRequestDataFetched && fundsRequestData);
  const airsLoaded = !!(airsDataFetched && airsData);

  const notifications =
    fundsRequestDataLoaded && airsLoaded ? getIncompleteCustomerTasks(fundsRequestData, airsData.airs) : [];

  return notifications.length;
};
