//
// Wrap the global `django` object with TypeScript functions.
//

declare const django: any;

// String interpolation, taken from Douglas Crockford's String.prototype.supplant implementation
// http://www.crockford.com/javascript/remedial.html
//
// The provided django.interpolation function is quite limited, it can only substitute a single
// value:
// https://github.com/django/django/blob/master/django/views/i18n.py#L143
//
function supplant(text: string, interpolation): string {
  return text.replace(/\{([^{}]*)\}/g, (a, b) => {
    const r = interpolation[b];
    return typeof r === "string" || typeof r === "number" ? r.toString() : a;
  });
}

//
// Functions in this module that take text to be localised should also be
// registered in customerweb/react-gettext-parser.config.json
//
// see: https://github.com/laget-se/react-gettext-parser#funcargumentsmap
//

function gettext(text: string, interpolation?: any): string {
  const translated = django?.gettext(text) ?? text;
  if (interpolation !== undefined) {
    return supplant(translated, interpolation);
  }
  return translated;
}

// Wrapper around ngettext and interpolate, use to plurify a string based on a number of items.
// use '{count}' as the interpolation placeholder in your .po file.
export function _plural(singular: string, plural: string, count: number): string {
  return supplant(django.ngettext(singular, plural, count), { count: count });
}

export function _brand(context, text) {
  return django.pgettext(context, text);
}

// Wrap call to gettext to support contextual translations (based on brand) where present
// Expected usages should be added frontend/customer/app/src/deprecated-translations.ts with `_brand`
export function brandedGetText(text: string, interpolation?: any): string {
  return gettext(text, interpolation);
}
