import { useLocation } from "react-router";
import { useDetectDeviceSize } from "@wayflyer/flyui";

import { FundingRequestRoutes, matchRoute } from "../funds-requests";

const stepperLookup: Record<FundingRequestRoutes, number | null> = {
  [FundingRequestRoutes.OFFERS]: 0,
  [FundingRequestRoutes.KYC]: 1,
  [FundingRequestRoutes.CONTRACTS_IN_PROGRESS]: 2,
  [FundingRequestRoutes.CONTRACTS_COMPLETED]: null,
  [FundingRequestRoutes.ORDER_FORM]: 2,
  [FundingRequestRoutes.BANK_ACCOUNT_INFO]: 2,
  [FundingRequestRoutes.EXPIRED]: null,
  [FundingRequestRoutes.DISQUALIFIED]: null,
  [FundingRequestRoutes.NOT_FOUND]: null,
};

export const useShouldShowStepper = (): { showStepper: boolean; currentIndex: number | null } => {
  const { pathname } = useLocation();
  const currentRoute = matchRoute(pathname);
  const { isDesktop } = useDetectDeviceSize();

  return {
    showStepper: stepperLookup[currentRoute] !== null && isDesktop,
    currentIndex: stepperLookup[currentRoute],
  };
};
